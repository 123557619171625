
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, Vue } from 'vue-property-decorator';

enum STATE {
  DEFAULT = 1,
  EXPANDED = 2,
}

@Component({
  name: 'SwipeRevealSubHeading',
})
export default class SwipeRevealSubHeading extends Vue {
  swipeGestureData: GestureData = {
    swipeHandle: undefined,
    rafPending: false,
    initialTouchPos: null,
    lastTouchPos: null,
    currentYPosition: 0,
    currentState: STATE.DEFAULT,
    handleSize: 0,

    handleHeight: NaN,
    slopValue: NaN, // distance used to determine if the touch was a tap or drag movement
  };

  $setupSwipeEvents(element: HTMLElement) {
    if (!element) {
      console.warn('[subHeader gesture] [$setupSwipeEvents] No element provided');
      return;
    }
    this.swipeGestureData.swipeHandle = element;
    this.swipeGestureData.handleHeight = this.swipeGestureData.swipeHandle.clientHeight;
    this.swipeGestureData.slopValue = this.swipeGestureData.handleHeight * (1 / 4);

    // Check if pointer events are supported.
    if (window.PointerEvent) {
      // Add Pointer Event Listener
      this.swipeGestureData.swipeHandle.addEventListener('pointerdown', this.$handleGestureStart, true);
      this.swipeGestureData.swipeHandle.addEventListener('pointermove', this.$handleGestureMove, true);
      this.swipeGestureData.swipeHandle.addEventListener('pointerup', this.$handleGestureEnd, true);
      this.swipeGestureData.swipeHandle.addEventListener('pointercancel', this.$handleGestureEnd, true);
    } else {
      // Add Touch Listener
      this.swipeGestureData.swipeHandle.addEventListener('touchstart', this.$handleGestureStart, true);
      this.swipeGestureData.swipeHandle.addEventListener('touchmove', this.$handleGestureMove, true);
      this.swipeGestureData.swipeHandle.addEventListener('touchend', this.$handleGestureEnd, true);
      this.swipeGestureData.swipeHandle.addEventListener('touchcancel', this.$handleGestureEnd, true);

      // Add Mouse Listener
      this.swipeGestureData.swipeHandle!.addEventListener('mousedown', this.$handleGestureStart, true);
    }
  }

  $teardownSwipeEvents() {
    // Check if pointer events are supported.
    if (window.PointerEvent) {
      // Add Pointer Event Listener
      this.swipeGestureData?.swipeHandle?.removeEventListener('pointerdown', this.$handleGestureStart, true);
      this.swipeGestureData?.swipeHandle?.removeEventListener('pointermove', this.$handleGestureMove, true);
      this.swipeGestureData?.swipeHandle?.removeEventListener('pointerup', this.$handleGestureEnd, true);
      this.swipeGestureData?.swipeHandle?.removeEventListener('pointercancel', this.$handleGestureEnd, true);
    } else {
      // Add Touch Listener
      this.swipeGestureData.swipeHandle?.removeEventListener('touchstart', this.$handleGestureStart, true);
      this.swipeGestureData.swipeHandle?.removeEventListener('touchmove', this.$handleGestureMove, true);
      this.swipeGestureData.swipeHandle?.removeEventListener('touchend', this.$handleGestureEnd, true);
      this.swipeGestureData.swipeHandle?.removeEventListener('touchcancel', this.$handleGestureEnd, true);

      // Add Mouse Listener
      this.swipeGestureData.swipeHandle?.removeEventListener('mousedown', this.$handleGestureStart, true);
    }
  }

  // On resize, change the slope value
  $resize() {
    this.swipeGestureData.handleHeight = this.swipeGestureData.swipeHandle!.clientHeight;
    this.swipeGestureData.slopValue = this.swipeGestureData.handleHeight * (1 / 4);
  }

  // Handle the start of gestures
  $handleGestureStart(evt: GestureEvent) {
    if (!this.$isValidEventTarget(evt.target as HTMLElement)) {
      return;
    }

    evt.preventDefault();

    if (window.TouchEvent && evt instanceof TouchEvent && evt.touches.length > 1) {
      return;
    }

    // Add the move and end listeners
    if (evt instanceof PointerEvent) {
      (evt.target as HTMLElement).setPointerCapture(evt.pointerId);
    } else {
      // Add Mouse Listeners
      document.addEventListener('mousemove', this.$handleGestureMove, true);
      document.addEventListener('mouseup', this.$handleGestureEnd, true);
    }

    this.swipeGestureData.initialTouchPos = this.$getGesturePointFromEvent(evt);

    this.swipeGestureData.swipeHandle!.style.transition = 'initial';
  }

  // Handle move gestures
  $handleGestureMove(evt: GestureEvent) {
    if (!this.$isValidEventTarget(evt.target as HTMLElement, true)) {
      return;
    }

    evt.preventDefault();

    this.swipeGestureData.lastTouchPos = this.$getGesturePointFromEvent(evt);

    if (this.swipeGestureData.rafPending) {
      return;
    }

    this.swipeGestureData.rafPending = true;
    window.requestAnimationFrame(this.$onAnimFrame);
  }

  // Handle end gestures
  $handleGestureEnd(evt: GestureEvent) {
    if (!this.$isValidEventTarget(evt.target as HTMLElement, true)) {
      return;
    }

    evt.preventDefault();

    if (window.TouchEvent && evt instanceof TouchEvent && evt.touches.length > 0) {
      return;
    }

    this.swipeGestureData.rafPending = false;

    // Remove Event Listeners
    if (evt instanceof PointerEvent) {
      (evt.target as HTMLElement).releasePointerCapture(evt.pointerId);
    } else {
      // Remove Mouse Listeners
      document.removeEventListener('mousemove', this.$handleGestureMove, true);
      document.removeEventListener('mouseup', this.$handleGestureEnd, true);
    }

    this.$updateSwipeRestPosition();

    this.swipeGestureData.initialTouchPos = null;
  }

  $updateSwipeRestPosition() {
    const differenceInY = this.swipeGestureData.initialTouchPos!.y - this.swipeGestureData.lastTouchPos!.y;
    this.swipeGestureData.currentYPosition = this.swipeGestureData.currentYPosition - differenceInY;

    // Go to the default state and change
    let newState = STATE.DEFAULT;

    // Check if we need to change state based on slop value
    if (Math.abs(differenceInY) > this.swipeGestureData.slopValue) {
      if (this.swipeGestureData.currentState === STATE.DEFAULT) {
        if (differenceInY < 0) {
          newState = STATE.EXPANDED;
        }
      } else {
        if (this.swipeGestureData.currentState === STATE.EXPANDED && differenceInY < 0) {
          newState = STATE.EXPANDED;
        } else if (this.swipeGestureData.currentState === STATE.EXPANDED && differenceInY > 0) {
          newState = STATE.DEFAULT;
        }
      }
    } else {
      newState = this.swipeGestureData.currentState;
    }

    this.$changeState(newState);

    this.swipeGestureData.swipeHandle!.style.transition = 'all 150ms ease-out';
  }

  $changeState(newState: number) {
    switch (newState) {
      case STATE.DEFAULT:
        this.swipeGestureData.currentYPosition = 0;
        break;
      case STATE.EXPANDED:
        this.swipeGestureData.currentYPosition = -(
          this.swipeGestureData.handleHeight -
          this.swipeGestureData.handleSize -
          44
        );
        break;
    }

    this.swipeGestureData.swipeHandle!.style.transform =
      'translateY(' + -this.swipeGestureData.currentYPosition + 'px)';

    this.swipeGestureData.currentState = newState;
  }

  $getGesturePointFromEvent(evt: GestureEvent) {
    const point: Point = { x: 0, y: 0 };

    if (window.TouchEvent && evt instanceof TouchEvent) {
      point.x = evt.targetTouches[0].clientX;
      point.y = evt.targetTouches[0].clientY;
    } else {
      // Either Mouse event or Pointer Event
      point.x = (evt as PointerEvent).clientX;
      point.y = (evt as PointerEvent).clientY;
    }

    return point;
  }

  $onAnimFrame() {
    if (!this.swipeGestureData.rafPending) {
      return;
    }

    const differenceInY = this.swipeGestureData.initialTouchPos!.y - this.swipeGestureData.lastTouchPos!.y;

    const newYTransform = Math.abs(this.swipeGestureData.currentYPosition) - differenceInY + 'px';
    this.swipeGestureData.swipeHandle!.style.transform = 'translateY(' + newYTransform + ')';

    this.swipeGestureData.rafPending = false;
  }

  $isValidEventTarget(target: HTMLElement, requireInitialTouchPos = false): boolean {
    let isValid = false;

    if (requireInitialTouchPos && !this.swipeGestureData.initialTouchPos) {
      return isValid;
    }

    const maxAllowedPxSize = 676; // bootstrap md to lg breakpoint
    const allowedDragClasses = ['pulldown-button', 'mobile-pulldown-wrapper', 'sub-header-wrapper'];
    const targetClassList = target?.classList?.value ?? '';
    if (document.body.clientWidth < maxAllowedPxSize && allowedDragClasses.includes(targetClassList)) {
      // Only allow user to drag if mobile, and if they've selected a viable target
      isValid = true;
    }
    return isValid;
  }

  render() {
    return null;
  }
}

interface GestureData {
  swipeHandle?: HTMLElement;
  rafPending: boolean;
  initialTouchPos: Point | null;
  lastTouchPos: Point | null;
  currentYPosition: number;
  currentState: STATE;
  handleSize: number;

  handleHeight: number;
  slopValue: number;
}

interface Point {
  x: number;
  y: number;
}

type GestureEvent = PointerEvent | TouchEvent | MouseEvent;
