import { productMap } from '@/constants/seeded/products';

/**
 * Use to get route path for any product ID
 * @param id ID of product in `productMap` that should always match a seeded product ID in DB
 * @returns URL as string for FE route navigationß
 */
export const getProductUrlById = (id?: number) => {
  const fallback = '';
  if (id) {
    const product = productMap.find((product) => product.id === id);
    if (product?.id) {
      return product.url;
    }
    return fallback;
  }
  return fallback;
};
