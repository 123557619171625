import store from '@/store/index';
import { isActiveUser } from './userPermissions';

// Dispatches for show routes
const routesWithShowDataRequired = ['podcasts', 'settings', 'tracking-urls', 'import-rss-feed'];

// Dispatches for social media routes
const routesWithSocialMediaRequired = ['podcasts/shows', 'settings', 'import-rss-feed'];

// Dispatches for clear active show routes
const routesWithClearShowRequired = ['import-rss-feed'];

// Dispatches for group routes
const routesWithGroupDataRequired = ['import-rss-feed'];

export async function handleDispatches(path) {
  const isAdmin = store.getters.isAdmin;
  const isActive = isActiveUser();
  if (origin === 'login') {
    // If Admin or Superadmin
    if (isAdmin && isActive) {
      await store.dispatch('fetchBrands');
      await store.dispatch('fetchUsers');
    }
    await store.dispatch('currentUser/requestUser');
    await store.dispatch('fetchAccount');
  }

  //handle beforeEnter dispatches when initiating brand switch from here
  if (path.includes('create')) {
    //update selected brand for C&P endpoint & fetch settings again
    //with the new brand id
    await store.dispatch('CreateAndPublishStore/setEndpoint');
    await store.dispatch('CreateAndPublishStore/getSettings');

    if (routesWithShowDataRequired.some((e) => path.includes(e))) {
      store.dispatch('CreateAndPublishStore/fetchAllShowsData');
    }

    if (routesWithSocialMediaRequired.some((e) => path.includes(e))) {
      store.dispatch('CreateAndPublishStore/getSocialMediaConnections');
    }

    if (routesWithClearShowRequired.some((e) => path.includes(e))) {
      store.dispatch('CreateAndPublishStore/clearActiveShow');
    }

    if (routesWithGroupDataRequired.some((e) => path.includes(e))) {
      store.dispatch('CreateAndPublishStore/fetchGroupSettings');
      store.dispatch('CreateAndPublishStore/setGroupMaps');
    }
  }
  return;
}
