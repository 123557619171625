import Vue from 'vue';
import { SSE } from '@/types/SSE';

export const setupBaseSSE = async (url, cfg) => {
  const msgServer: SSE = await Vue.SSE(url, cfg);

  msgServer.onError((e) => {
    console.error('Lost connection to SSE server', e);
    // Will automatically try to reconnect
  });

  msgServer.subscribe('', (data) => {
    console.warn('Received a message without an event', data);
  });

  return msgServer;
};
