// 08/30/21 Keep but change breakpoints, find specs
import type { Module } from 'vuex';
import type { RootState } from '@/types/store';

export const initialState: MediaQueriesState = {
  windowWidth: window.innerWidth,
  collapseSidebar: false,
  showSidebar: false,
  hasSidebar: true,
  isPublicHeader: false, // We can probably remove this -wc
};

export interface MediaQueriesState {
  windowWidth: number;
  collapseSidebar: boolean;
  showSidebar: boolean;
  hasSidebar: boolean;
  isPublicHeader: boolean;
}

const state = initialState;

const getters = {
  windowWidthXS: (state) => state.windowWidth < 768,
  windowWidthMD: (state) => state.windowWidth >= 768 && state.windowWidth < 1024,
  showSidebar: (state) => state.showSidebar,
  collapseSidebar: (state) => state.collapseSidebar,
  hasSidebar: (state) => state.hasSidebar,
  isPublicHeader: (state) => state.isPublicHeader,
};

const actions = {
  toggleCollapseSidebar: ({ commit }) => commit('toggleCollapseSidebar'),
  toggleSidebar: ({ commit }) => commit('toggleSidebar'),
  setWindowWidth: ({ commit }) => commit('setWindowWidth'),
  setPublicHeader: ({ commit }) => commit('setPublicHeader'),
};

const mutations = {
  setWindowWidth: (state) => {
    state.windowWidth = window.innerWidth;
    state.collapseSidebar = state.windowWidth < 1023;
  },
  toggleCollapseSidebar: (state) => (state.collapseSidebar = !state.collapseSidebar),
  toggleSidebar: (state) => (state.showSidebar = !state.showSidebar),
  setPublicHeader: (state) => {
    state.isPublicHeader = true;
    state.hasSidebar = false;
  },
};

const mediaQueriesModule: Module<MediaQueriesState, RootState> = {
  state,
  getters,
  actions,
  mutations,
};

export default mediaQueriesModule;
