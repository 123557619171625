import { productMap } from '@/constants/seeded/products';
import store from '@/store/index';

const BASIC_USER = 3;
const ACTIVE_USER = 1;

const ACTIVE_SUBSCRIPTION_STATES: Array<string> = [
  'active',
  'assessing',
  'paused',
  'pending',
  'past_due',
  'trialing',
  'unpaid',
  'soft_failure',
];

function getAuthenticatedUser() {
  return store.getters['authenticatedUser'];
}

function getAccount() {
  return store.getters['getAccount'];
}

const userHasProductAccess = (path) => {
  const userProducts = store.getters['userProducts'];

  // Find a product that matches path
  let productIdForPath;
  productMap.forEach((product) => {
    if (path.includes(product.url.replace('/', ''))) {
      productIdForPath = product.id;
    }
  });

  // Check if user has access to product
  if (userProducts.findIndex((product) => product.id === productIdForPath) !== -1) {
    return true;
  }

  return false;
};

const isValidSubscription = () => {
  const account = getAccount();
  return ACTIVE_SUBSCRIPTION_STATES.includes(account.data.subscriptionStatus);
};

const isCCAdmin = () => {
  const authenticatedUser = getAuthenticatedUser();
  return authenticatedUser && authenticatedUser.roleId !== BASIC_USER;
};

const isActiveUser = () => {
  const authenticatedUser = getAuthenticatedUser();
  return authenticatedUser && authenticatedUser.statusId === ACTIVE_USER;
};

const isMultiBrand = () => {
  const account = getAccount();
  return typeof account.data.maxBrands === 'number' && account.data.maxBrands !== 1;
};

export { userHasProductAccess, isValidSubscription, isCCAdmin, isActiveUser, isMultiBrand };
