import UserModel from '@/models/UserModel';
import HttpClient from '@/services/common/HttpClient';

export type GetMyProfileResponse = {
  brands: [];
  defaultBrandId: number;
  ecId?: string;
  email: UserModel['email'];
  firstName: UserModel['firstName'];
  id: UserModel['id'];
  lastLogin: UserModel['lastLogin'];
  lastName: UserModel['lastName'];
  phone: UserModel['phone'];
};

export type UpdateMyProfileRequest = {
  defaultBrandId: number;
  email: UserModel['email'];
  firstName: UserModel['firstName'];
  lastName: UserModel['lastName'];
  phone: UserModel['phone'];
};

export type UpdateMyProfileResponse = {
  brands: [];
  defaultBrandId: number;
  ecId?: string;
  email: UserModel['email'];
  firstName: UserModel['firstName'];
  lastName: UserModel['lastName'];
  phone: UserModel['phone'];
  errors?: string[];
  authentication: Authentication;
};

export type GetUserDataRequest = {
  email: UserModel['email'];
};

export type GetUserDataResponse = {
  email: UserModel['email'];
  firstName: UserModel['firstName'];
  id: UserModel['id'];
  lastName: UserModel['lastName'];
  roleId: UserModel['roleId'];
  statusId: UserModel['statusId'];
  phone?: UserModel['phone'];
  lastLogin?: UserModel['lastLogin'];
  brands: {
    active: boolean;
    campaigns: {
      active: boolean;
      color: string;
      id: number;
      name: string;
    };
    id: number;
    name: string;
  }[];
  products: { id: number; name: string }[];
};

type Authentication = {
  userHasBrandAccess: boolean;
  atLeastOneBrand: boolean;
};
export default class MyProfileService {
  private http: HttpClient = HttpClient.getInstance();
  private endpoint = '/my-profile';

  public getMyProfile(): Promise<GetMyProfileResponse> {
    return this.http.get(this.endpoint);
  }

  public updateMyProfile(payload: UpdateMyProfileRequest): Promise<UpdateMyProfileResponse> {
    return this.http.put(this.endpoint, payload);
  }

  public getMyProfileUserData(): Promise<GetUserDataResponse> {
    return this.http.get(`${this.endpoint}/user-data`);
  }
}
