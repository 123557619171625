import { render, staticRenderFns } from "./PinterestIcon.vue?vue&type=template&id=f035753a&scoped=true&"
import script from "./PinterestIcon.vue?vue&type=script&lang=ts&"
export * from "./PinterestIcon.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f035753a",
  null
  
)

export default component.exports