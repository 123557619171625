import HttpClient from '@/services/common/HttpClient';
import type { AyrshareProfile, GrowthTip, SocialAnalytics } from '@/types/audience/';

import store from '@/store';

export default class AudienceService {
  private http: HttpClient = HttpClient.getInstance();

  private get brandId() {
    const brandId = store?.getters['selectedBrand']?.id;
    if (!brandId) {
      // Fallback to localStorage
      try {
        const _store = JSON.parse(JSON.stringify(localStorage.getItem('vuex')));
        const brand = JSON.parse(_store)?.BrandStore.selectedBrand;
        return brand.id;
      } catch {
        console.warn('[AudienceAyrshareService] BrandId not found');
      }
    }
    return brandId;
  }

  // Endpoint prefix definitions.
  private get audiencePrefix() {
    return `/audience/${this.brandId}`;
  }

  public getAyrShareProfile(ignoreCached: boolean): Promise<AyrshareProfile> {
    const query = ignoreCached ? '?ignoreCached=true' : '';
    return this.http.get(`${this.audiencePrefix}/profile${query}`);
  }

  public getAyrShareConnectUrl(): Promise<string> {
    return this.http.post(`${this.audiencePrefix}/connectSocial`, undefined);
  }

  public getSocialAnalytics(query: string): Promise<SocialAnalytics> {
    return this.http.get(`${this.audiencePrefix}/profile/analytics${query}`);
  }

  public getGrowthTips(): Promise<GrowthTip[]> {
    return this.http.get(`${this.audiencePrefix}/growthTips`);
  }

  public getLeaderboardRankings(): Promise<Record<string, unknown>[]> {
    return this.http.get(`${this.audiencePrefix}/leaderboards`);
  }

  public toggleLeaderboardOptIn(): Promise<Record<string, unknown>> {
    return this.http.post(`${this.audiencePrefix}/leaderboards/toggleOptIn`, undefined);
  }
}
