import { handleAjax, parseStationFromEmpireCollectionId } from '../core/util';
import CreateAndPublishAnalyticsService from '@/services/CreateAndPublishAnalyticsService';
import moment from 'moment';

const AnalyticsDataService = new CreateAndPublishAnalyticsService();

function getPayloadForEpisodeNames({ episodeData, stationName }) {
  const payload = [];

  {
    // get min/max aw_playlist_ids per station for non-iHeart stations
    const stationList = episodeData.reduce((accumulator, { aw_collection_id }) => {
      const station = parseStationFromEmpireCollectionId(aw_collection_id);
      return station ? accumulator.add(station) : accumulator;
    }, new Set());

    stationList.forEach((station) => {
      // get a starting place for the reduce callbacks below
      const firstMatchingId = episodeData.find(({ aw_collection_id }) =>
        aw_collection_id.startsWith(station)
      ).aw_episode_id;

      // find min and max aw_episode_ids for the given station
      const min = episodeData.reduce((currentMinId, { aw_collection_id, aw_episode_id }) => {
        if (aw_collection_id.startsWith(station) && Number(aw_episode_id) < Number(currentMinId)) {
          return aw_episode_id;
        } else {
          return currentMinId;
        }
      }, firstMatchingId);

      const max = episodeData.reduce((currentMaxId, { aw_collection_id, aw_episode_id }) => {
        if (aw_collection_id.startsWith(station) && Number(aw_episode_id) > Number(currentMaxId)) {
          return aw_episode_id;
        } else {
          return currentMaxId;
        }
      }, firstMatchingId);

      payload.push({ station, min, max });
    });
  }

  {
    // now get min/max aw_episode_ids for iHeart (station doesn't matter for these)
    const firstMatchingElement = episodeData.find(
      ({ aw_collection_id, aw_episode_id }) => !isNaN(aw_collection_id) && aw_episode_id && aw_episode_id.length > 7
    );
    const firstMatchingId = firstMatchingElement ? firstMatchingElement.aw_episode_id : undefined;

    if (firstMatchingId !== undefined) {
      const min = episodeData.reduce((currentMinId, { aw_collection_id, aw_episode_id }) => {
        if (
          !isNaN(aw_collection_id) &&
          Number(aw_episode_id) < Number(currentMinId) &&
          aw_episode_id &&
          aw_episode_id.length > 7
        ) {
          return aw_episode_id;
        } else {
          return currentMinId;
        }
      }, firstMatchingId);

      const max = episodeData.reduce((currentMaxId, { aw_collection_id, aw_episode_id }) => {
        if (!isNaN(aw_collection_id) && Number(aw_episode_id) > Number(currentMaxId)) {
          return aw_episode_id;
        } else {
          return currentMaxId;
        }
      }, firstMatchingId);
      payload.push({ station: '', min, max });
    }
  }

  {
    // Hack to get episode names for playlists iHeart failed to ingest
    const filteredDataForFailedIngestion = episodeData.filter(({ aw_collection_id, aw_episode_id }) => {
      return !isNaN(aw_collection_id) && aw_episode_id && aw_episode_id.length < 7;
    });

    if (filteredDataForFailedIngestion.length > 0) {
      const station = stationName;
      // get a starting place for the reduce callbacks below
      const firstMatchingId = filteredDataForFailedIngestion[0].aw_episode_id;

      // find min and max aw_episode_ids for the given station
      const min = filteredDataForFailedIngestion.reduce((currentMinId, { aw_episode_id }) => {
        if (Number(aw_episode_id) < Number(currentMinId)) {
          return aw_episode_id;
        } else {
          return currentMinId;
        }
      }, firstMatchingId);

      const max = filteredDataForFailedIngestion.reduce((currentMaxId, { aw_episode_id }) => {
        if (Number(aw_episode_id) > Number(currentMaxId)) {
          return aw_episode_id;
        } else {
          return currentMaxId;
        }
      }, firstMatchingId);

      payload.push({ station, min, max });
    }
  }

  return payload;
}

export default {
  namespaced: true,

  state: {
    dateRange: {
      start: null,
      end: null,
    },
    filterByShows: [],
    allShowNamesById: null,
    showAnalytics: null,
    allEpisodeNamesById: null,
    episodeAnalytics: null,
    countryAnalytics: null,
    regionAnalytics: null,
    platformAnalytics: null,
    countriesFilter: [],
    platformsFilter: [],
    csvJobIds: [],
  },

  getters: {
    stationName(_state, _getters, _rootState, rootGetters) {
      return rootGetters['CreateAndPublishStore/station_name'];
    },

    basePayload(state, getters) {
      return {
        start_date: state.dateRange.start,
        end_date: state.dateRange.end,
        station_names: getters.stationName?.toLowerCase(),
      };
    },

    /**
     * Turns state.filterByShows array into a spreadable object for show_id param of the analytics endpoints
     */
    showFilter(state) {
      const showFilterString = state.filterByShows.join(',');
      return showFilterString !== '' ? { show_id: showFilterString } : {};
    },

    allShowsList(state) {
      // Return an array of { id, name } objects for shows
      if (!state.allShowNamesById) {
        return [];
      } else {
        return Object.entries(state.allShowNamesById).map(([id, name]) => ({ id, name }));
      }
    },

    showAnalyticsTableData(state) {
      if (!state.showAnalytics || !state.allShowNamesById) {
        return [];
      } else {
        return state.showAnalytics.data.map(({ aw_collection_id, downloads, ltr, unique_reach, start_time }) => {
          const date = start_time ? { date: moment.utc(start_time).format('MM-DD-YYYY') } : {};
          return {
            date,
            show: state.allShowNamesById[aw_collection_id],
            downloads,
            percentTotal: ((downloads / state.showAnalytics.totals.downloads) * 100).toFixed(2) || 0,
            unique_reach,
            ltr,
            _aw_collection_id: aw_collection_id,
          };
        });
      }
    },

    episodeAnalyticsTableData(state, getters) {
      if (!state.episodeAnalytics || !state.allShowNamesById || !state.allEpisodeNamesById) {
        return [];
      } else {
        // eslint-disable-next-line
        return state.episodeAnalytics.data.map(({ publish_date, aw_collection_id, aw_episode_id, downloads, ltr, unique_reach, start_time }) => {
            let station = null;
            station = parseStationFromEmpireCollectionId(aw_collection_id);
            const isFailedToIngestByIHeart =
              !station && !isNaN(aw_collection_id) && aw_episode_id && aw_episode_id.length < 7;
            if (isFailedToIngestByIHeart) {
              station = getters.stationName;
            }
            const episodeId = station ? `${station}${aw_episode_id}` : aw_episode_id;
            const date = start_time ? { date: moment.utc(start_time).format('MM-DD-YYYY') } : {};

            return {
              aw_episode_id,
              ...date,
              show: state.allShowNamesById[aw_collection_id],
              episode: state.allEpisodeNamesById[episodeId] ? state.allEpisodeNamesById[episodeId].name : '',
              createdAt: state.allEpisodeNamesById[episodeId] ? state.allEpisodeNamesById[episodeId].timestamp : '',
              downloads,
              percentTotal: ((downloads / state.episodeAnalytics.totals.downloads) * 100).toFixed(1),
              unique_reach,
              ltr,
              publish_date,
            };
          }
        );
      }
    },

    countryAnalyticsTableData(state, getters) {
      if (!state.countryAnalytics || !state.allShowNamesById || !state.allEpisodeNamesById) {
        return [];
      } else {
        const context =
          state.countryAnalytics.data[0] && state.countryAnalytics.data[0].aw_episode_id
            ? 'episodes'
            : 'shows' || 'episodes';
        if (context === 'shows') {
          return state.countryAnalytics.data.map(
            ({ publish_date, aw_collection_id, downloads, ltr, start_time, unique_reach, country }) => {
              const date = start_time ? { date: moment.utc(start_time).format('MM-DD-YYYY') } : {};

              return {
                ...date,
                show: state.allShowNamesById[aw_collection_id],
                downloads,
                percentTotal: ((downloads / state.countryAnalytics.totals.downloads) * 100).toFixed(1),
                unique_reach,
                ltr,
                country,
                publish_date,
              };
            }
          );
        } else {
          return state.countryAnalytics.data.map(
            ({ publish_date, aw_collection_id, aw_episode_id, downloads, ltr, start_time, unique_reach, country }) => {
              let station = null;
              station = parseStationFromEmpireCollectionId(aw_collection_id);
              const isFailedToIngestByIHeart =
                !station && !isNaN(aw_collection_id) && aw_episode_id && aw_episode_id.length < 7;
              if (isFailedToIngestByIHeart) {
                station = getters.stationName;
              }
              const episodeId = station ? `${station}${aw_episode_id}` : aw_episode_id;
              const date = start_time ? { date: moment.utc(start_time).format('MM-DD-YYYY') } : {};

              return {
                aw_episode_id,
                ...date,
                show: state.allShowNamesById[aw_collection_id],
                episode: state.allEpisodeNamesById[episodeId] ? state.allEpisodeNamesById[episodeId].name : '',
                createdAt: state.allEpisodeNamesById[episodeId] ? state.allEpisodeNamesById[episodeId].timestamp : '',
                downloads,
                percentTotal: ((downloads / state.countryAnalytics.totals.downloads) * 100).toFixed(1),
                unique_reach,
                ltr,
                country,
                publish_date,
              };
            }
          );
        }
      }
    },

    platformAnalyticsTableData(state, getters) {
      if (!state.platformAnalytics || !state.allShowNamesById || !state.allEpisodeNamesById) {
        return [];
      } else {
        const context =
          state.platformAnalytics.data[0] && state.platformAnalytics.data[0].aw_episode_id
            ? 'episodes'
            : 'shows' || 'episodes';
        if (context === 'shows') {
          return state.platformAnalytics.data.map(
            ({ publish_date, aw_collection_id, downloads, ltr, start_time, unique_reach, platform }) => {
              const date = start_time ? { date: moment.utc(start_time).format('MM-DD-YYYY') } : {};

              return {
                ...date,
                show: state.allShowNamesById[aw_collection_id],
                downloads,
                percentTotal: ((downloads / state.platformAnalytics.totals.downloads) * 100).toFixed(1),
                unique_reach,
                ltr,
                platform,
                publish_date,
              };
            }
          );
        } else {
          return state.platformAnalytics.data.map(
            ({ publish_date, aw_collection_id, aw_episode_id, downloads, ltr, start_time, unique_reach, platform }) => {
              let station = null;
              station = parseStationFromEmpireCollectionId(aw_collection_id);
              const isFailedToIngestByIHeart =
                !station && !isNaN(aw_collection_id) && aw_episode_id && aw_episode_id.length < 7;
              if (isFailedToIngestByIHeart) {
                station = getters.stationName;
              }
              const episodeId = station ? `${station}${aw_episode_id}` : aw_episode_id;
              const date = start_time ? { date: moment.utc(start_time).format('MM-DD-YYYY') } : {};

              return {
                aw_episode_id,
                ...date,
                show: state.allShowNamesById[aw_collection_id],
                episode: state.allEpisodeNamesById[episodeId] ? state.allEpisodeNamesById[episodeId].name : '',
                createdAt: state.allEpisodeNamesById[episodeId] ? state.allEpisodeNamesById[episodeId].timestamp : '',
                downloads,
                percentTotal: ((downloads / state.platformAnalytics.totals.downloads) * 100).toFixed(1),
                unique_reach,
                ltr,
                platform,
                publish_date,
              };
            }
          );
        }
      }
    },
  },

  mutations: {
    SET_DATERANGE(state, dateRange) {
      state.dateRange = dateRange;
    },
    SET_ALL_SHOW_NAMES_BY_ID(state, allShowNamesById) {
      for (const [key, value] of Object.entries(allShowNamesById)) {
        // Get rid of any shows w/o titles.
        value === '' && delete allShowNamesById[key];
      }
      state.allShowNamesById = allShowNamesById;
    },

    SET_SHOW_ANALYTICS(state, showAnalytics) {
      state.showAnalytics = showAnalytics;
    },

    SET_ALL_EPISODE_NAMES_BY_ID(state, allEpisodeNamesById) {
      state.allEpisodeNamesById = allEpisodeNamesById;
    },

    SET_EPISODE_ANALYTICS(state, episodeAnalytics) {
      state.episodeAnalytics = episodeAnalytics;
      state.totalRecords = episodeAnalytics.totals.episode_count;
    },

    SET_COUNTRY_ANALYTICS(state, countryAnalytics) {
      state.countryAnalytics = countryAnalytics;
      state.totalRecords = countryAnalytics.totals.episode_count;
      if (
        countryAnalytics &&
        countryAnalytics.totals &&
        countryAnalytics.totals.country_codes &&
        state.countriesFilter.length <= 1
      ) {
        state.countriesFilter = countryAnalytics.totals.country_codes.map(function (i) {
          return { label: i, value: i };
        });
      }
    },

    SET_REGION_ANALYTICS(state, regionAnalytics) {
      state.regionAnalytics = regionAnalytics;
    },

    SET_PLATFORM_ANALYTICS(state, platformAnalytics) {
      state.platformAnalytics = platformAnalytics;
      state.totalRecords = platformAnalytics.totals.episode_count;
      if (
        platformAnalytics &&
        platformAnalytics.totals &&
        platformAnalytics.totals.platforms &&
        state.platformsFilter.length <= 1
      ) {
        state.platformsFilter =
          platformAnalytics.totals &&
          platformAnalytics.totals.platforms.map(function (i) {
            return { label: i, value: i };
          });
      }
    },

    SET_SHOW_FILTER(state, filterByShows) {
      state.filterByShows = filterByShows;
    },

    CLEAR_ALL_ANALYTICS_DATA(state) {
      state.dateRange = {
        start: null,
        end: null,
      };
      state.filterByShows = [];
      state.allShowNamesById = null;
      state.showAnalytics = null;
      state.allEpisodeNamesById = null;
      state.episodeAnalytics = null;
      state.countryAnalytics = null;
      state.regionAnalytics = null;
      state.platformAnalytics = null;
      state.countriesFilter = [];
      state.platformsFilter = [];
      // intentionally don't clear csvJobIds
    },

    ADD_CSV_JOB_ID(state, { job_id }) {
      state.csvJobIds.push(job_id);
    },

    REMOVE_CSV_JOB_ID(state, jobId) {
      const index = state.csvJobIds.findIndex((id) => jobId === id);
      if (index !== -1) {
        state.csvJobIds.splice(index, 1);
      }
    },
  },

  actions: {
    /**
     * Gets map of show titles based on aw_collection_ids
     * @param {Object} ctx vuex context object
     * @returns {Promise<void>}
     */
    async getAllShowsById({ dispatch, commit }) {
      await handleAjax({
        request: AnalyticsDataService.getEmpireCollectionIdToShowMap(),
        dispatch,
        commit,
        mutation: 'SET_ALL_SHOW_NAMES_BY_ID',
        errmsg: 'Unable to talk to server to get shows',
      });
    },

    /**
     * Gets show analytics data
     * @param {Object} ctx vuex context object
     * @param {Object} options valid keys are: page, show_id (aw_collection_id)
     * @returns {Promise<void>}
     */
    async getShowAnalytics({ dispatch, commit, getters }, options) {
      const payload = {
        ...getters.basePayload,
        ...getters.showFilter,
        ...options,
      };
      await handleAjax({
        request: AnalyticsDataService.getPodcastShowAnalytics(payload),
        dispatch,
        commit,
        mutation: 'SET_SHOW_ANALYTICS',
        errmsg: 'Unable to talk to server to get show analytics',
      });
    },

    /**
     * Gets a map of playlist data (title, createdAt, playlist_id) by aw_episode_ids.
     * Only called by other actions
     * @param {Object} ctx vuex context object
     * @param {Object} payload payload generated by helper function getPayloadForEpisodeNames
     * @returns {Promise<void>}
     */
    getAllEpisodeNamesById({ dispatch, commit }, payload) {
      handleAjax({
        request: AnalyticsDataService.getEmpireEpisodeIdToPodcastEpisodeMap(payload),
        dispatch,
        commit,
        mutation: 'SET_ALL_EPISODE_NAMES_BY_ID',
        errmsg: 'Unable to talk to server to get episode names',
      });
    },

    /**
     * Gets episode analytics data
     * @param {Object} ctx vuex context object
     * @param {Object} options valid keys are: page, show_id (aw_collection_id)
     * @returns {Promise<void>}
     */
    async getEpisodeAnalytics({ dispatch, commit, getters }, options) {
      const payload = {
        ...getters.basePayload,
        ...getters.showFilter,
        ...options,
      };
      await handleAjax({
        request: AnalyticsDataService.getPodcastEpisodeAnalytics(payload),
        dispatch,
        commit,
        mutation: 'SET_EPISODE_ANALYTICS',
        callback(error, episodeAnalytics) {
          if (!error && episodeAnalytics) {
            const payload = getPayloadForEpisodeNames({
              episodeData: episodeAnalytics.data,
              // TODO: Replace with actual brand name
              stationName: getters.stationName,
            });
            dispatch('getAllEpisodeNamesById', payload);
          }
        },
        errmsg: 'Unable to talk to server to get episode analytics',
      });
    },

    /**
     * Gets analytics data for counties. Depending on how dimension is set, it can get data by shows or episodes (default)
     * @param {Object} ctx vuex context object
     * @param {Object} options valid keys are: page, granularity, countries_list, dimension, show_id (aw_collection_id)
     * @returns {Promise<void>}
     */
    async getCountryAnalytics({ dispatch, commit, getters }, options) {
      const payload = {
        ...getters.basePayload,
        ...getters.showFilter,
        ...options,
      };
      await handleAjax({
        request: AnalyticsDataService.getPodcastCountryAnalytics(payload),
        dispatch,
        commit,
        mutation: 'SET_COUNTRY_ANALYTICS',
        callback(error, countryAnalytics) {
          const dimension = options && options.dimension;
          // If not dimension, default is 'episode'; we don't want to run this if dimension === 'show'
          if (!dimension || dimension === 'episode') {
            if (!error && countryAnalytics) {
              const payload = getPayloadForEpisodeNames({
                episodeData: countryAnalytics.data,
                stationName: getters.stationName,
              });
              dispatch('getAllEpisodeNamesById', payload);
            }
          }
        },
        errmsg: 'Unable to talk to server to get country analytics',
      });
    },

    /**
     * Gets analytics data for regions. Depending on how dimension is set, it can get data by shows or episodes (default)
     * @param {Object} ctx vuex context object
     * @param {Object} options valid keys are: page, granularity, regions, dimension, show_id (aw_collection_id)
     * @returns {Promise<void>}
     */
    async getRegionAnalytics({ dispatch, commit, getters }, options) {
      const payload = {
        ...getters.basePayload,
        ...getters.showFilter,
        ...options,
      };
      await handleAjax({
        request: AnalyticsDataService.getPodcastRegionAnalytics(payload),
        dispatch,
        commit,
        mutation: 'SET_REGION_ANALYTICS',
        callback(error, regionAnalytics) {
          const dimension = options && options.dimension;
          // If not dimension, default is 'episode'; we don't want to run this if dimension === 'show'
          if (!dimension || dimension === 'episode') {
            if (!error && regionAnalytics) {
              const payload = getPayloadForEpisodeNames({
                episodeData: regionAnalytics.data,
                stationName: getters.stationName,
              });
              dispatch('getAllEpisodeNamesById', payload);
            }
          }
        },
        errmsg: 'Unable to talk to server to get region analytics',
      });
    },

    /**
     * Gets analytics data for platforms. Depending on how dimension is set, it can get data by shows or episodes (default)
     * @param {Object} ctx vuex context object
     * @param {Object} options valid keys are: page, granularity, plataforms, dimension, show_id (aw_collection_id)
     * @returns {Promise<void>}
     */
    async getPlatformAnalytics({ dispatch, commit, getters }, options) {
      const payload = {
        ...getters.basePayload,
        ...getters.showFilter,
        ...options,
      };
      await handleAjax({
        request: AnalyticsDataService.getPodcastPlatformAnalytics(payload),
        dispatch,
        commit,
        mutation: 'SET_PLATFORM_ANALYTICS',
        callback(error, platformAnalytics) {
          const dimension = options && options.dimension;
          // If not dimension, default is 'episode'; we don't want to run this if dimension === 'show'
          if (!dimension || dimension === 'episode') {
            if (!error && platformAnalytics) {
              const payload = getPayloadForEpisodeNames({
                episodeData: platformAnalytics.data,
                stationName: getters.stationName,
              });
              dispatch('getAllEpisodeNamesById', payload);
            }
          }
        },
        errmsg: 'Unable to talk to server to get platform analytics',
      });
    },

    async requestShowAnalyticsCsv({ dispatch, commit, getters }, options) {
      const payload = {
        ...getters.basePayload,
        ...getters.showFilter,
        ...options,
      };
      delete payload.page; // Not relevant to CSV requests
      await handleAjax({
        request: AnalyticsDataService.getPodcastShowAnalyticsCSV(payload),
        dispatch,
        commit,
        mutation: options && options.hasOwnProperty.call('email') ? null : 'ADD_CSV_JOB_ID',
        errmsg: 'Unable to talk to server to request CSV',
      });
    },

    async requestEpisodeAnalyticsCsv({ dispatch, commit, getters }, options) {
      const payload = {
        ...getters.basePayload,
        ...getters.showFilter,
        ...options,
      };
      delete payload.page; // Not relevant to CSV requests
      await handleAjax({
        request: AnalyticsDataService.getPodcastEpisodeAnalyticsCSV(payload),
        dispatch,
        commit,
        mutation: options && options.hasOwnProperty.call('email') ? null : 'ADD_CSV_JOB_ID',
        errmsg: 'Unable to talk to server to requset CSV',
      });
    },

    async requestCountryAnalyticsCsv({ dispatch, commit, getters }, options) {
      const payload = {
        ...getters.basePayload,
        ...getters.showFilter,
        ...options,
      };
      delete payload.page; // Not relevant to CSV requests
      await handleAjax({
        request: AnalyticsDataService.getPodcastCountryAnalyticsCSV(payload),
        dispatch,
        commit,
        mutation: options && options.hasOwnProperty.call('email') ? null : 'ADD_CSV_JOB_ID',
        errmsg: 'Unable to talk to server to requset CSV',
      });
    },

    async requestRegionAnalyticsCsv({ dispatch, commit, getters }, options) {
      const payload = {
        ...getters.basePayload,
        ...getters.showFilter,
        ...options,
      };
      delete payload.page; // Not relevant to CSV requests
      await handleAjax({
        request: AnalyticsDataService.getPodcastRegionAnalyticsCSV(payload),
        dispatch,
        commit,
        mutation: options && options.hasOwnProperty.call('email') ? null : 'ADD_CSV_JOB_ID',
        errmsg: 'Unable to talk to server to requset CSV',
      });
    },

    async requestPlatformAnalyticsCsv({ dispatch, commit, getters }, options) {
      const payload = {
        ...getters.basePayload,
        ...getters.showFilter,
        ...options,
      };
      delete payload.page; // Not relevant to CSV requests
      await handleAjax({
        request: AnalyticsDataService.getPodcastPlatformAnalyticsCSV(payload),
        dispatch,
        commit,
        mutation: options && options.hasOwnProperty.call('email') ? null : 'ADD_CSV_JOB_ID',
        errmsg: 'Unable to talk to server to requset CSV',
      });
    },

    downloadCsvByJobId({ state, commit, rootGetters, getters }, jobId) {
      if (state.csvJobIds.includes(jobId)) {
        const app = document.getElementsByTagName('body')[0];
        const el = document.createElement('A');
        const mediaUrl = rootGetters['CreateAndPublishStore/mediaUrl'];
        const station = getters.stationName;
        el.href = `${mediaUrl}/${station}/api/v1/${jobId}/download_csv.csv`;
        const now = new Date();
        const nowAsDateString = `${now.getMonth()}_${now.getDate()}_${now.getFullYear()}_${now.getHours()}_${now.getMinutes()}`;
        el.download = `podcast_analytics_${nowAsDateString}.csv`;
        app.appendChild(el);
        el.click();
        app.removeChild(el);
        commit('REMOVE_CSV_JOB_ID', jobId);
      }
    },

    showCsvError({ commit, state }, jobId) {
      const index = state.csvJobIds.findIndex((id) => id === jobId);
      if (index !== -1) {
        commit('REMOVE_CSV_JOB_ID', jobId);
        commit(
          'CreateAndPublishStore/SET_MESSAGE',
          {
            name: 'Analytics CSV Download',
            details: "Couldn't download CSV. Please try re-exporting.",
            type: 'error',
          },
          {
            root: true,
          }
        );
      }
    },
  },
};
