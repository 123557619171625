export const STATION_REGEX = /^\/?([^/]+)/;

export const urlStation = () => {
  const m = STATION_REGEX.exec(location.pathname || '/');
  if (!m) return undefined;

  const station = m.length ? m[1] : '';

  return station;
};

export const stationBase = () => `${location.protocol}//${location.host}/${urlStation()}/`;

export const stationsApi = () => `${location.protocol}//${location.host}/stations/`;
