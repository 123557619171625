import { productMap } from '@/constants/seeded/products';
import { ProductMap } from '@/types/ProductMap';

/**
 *
 * @param id Product ID
 * @param attribute Attribute in ProductMap
 * @returns the attribute's value
 */
export const getProductByIdByAttribute = (id: ProductMap['id'], attribute: string) => {
  const fallback = '';
  if (id) {
    const product = productMap.find((product) => product.id === id);
    if (product?.id) {
      return product[attribute];
    }
    return fallback;
  }
  return fallback;
};
