import Vue from 'vue';
import CreateAndPublishService from '@/services/CreateAndPublishService';
import { handleAjax } from '../core/util';
import HttpClient from '@/services/common/HttpClient';

const http = HttpClient.getInstance();
// const endpoint = `${process.env.VUE_APP_API_URL}/createandpublish/${getBrand()}`;

export default {
  namespaced: true,

  state: {
    draftShow: {},
    newSMCLocation: undefined,
    rssSubtypes: [],
    maxStepCompleted: 0,
  },

  getters: {
    show: (_state, _getters, _rootState, rootGetters) => {
      return rootGetters['CreateAndPublishStore/activeShow'] || {};
    },

    showSMCs: (_state, getters) => {
      return (getters.show && getters.show.social_media_connections) || [];
    },

    masterSMCSubtype: (state) => {
      return (state.rssSubtypes && state.rssSubtypes.find(({ short_name }) => short_name === 'master')) || undefined;
    },

    newSMCId: (state) => {
      return (state.newSMCLocation && state.newSMCLocation.split('/')[5]) || undefined;
    },

    shouldShowAlert: (state) => state.shouldShowAlert,
  },

  mutations: {
    SET_MAX_STEP_COMPLETED(state, step) {
      state.maxStepCompleted = step;
    },

    SET_DRAFT_SHOW(state, draftShow) {
      state.draftShow = draftShow;
    },

    UPDATE_DRAFT_SHOW(state, newData) {
      const existingData = state.draftShow;

      state.draftShow = {
        ...existingData,
        ...newData,
      };
    },

    CLEAR_DRAFT_SHOW(state) {
      state.draftShow = {};
    },

    SET_RSS_SUBTYPES(state, rssSubtypes) {
      state.rssSubtypes = (rssSubtypes && rssSubtypes.objects) || [];
    },

    SET_NEW_SOCIAL_MEDIA_CONNECTION_LOC(state, resource_uri) {
      state.newSMCLocation = resource_uri;
    },

    CLEAR_NEW_SOCIAL_MEDIA_CONNECTION_LOC(state) {
      state.newSMCLocation = undefined;
    },
  },

  actions: {
    ensureDraftShow({ commit, rootGetters }) {
      const activeShow = rootGetters['CreateAndPublishStore/activeShow'];
      if (activeShow && Object.hasOwnProperty.call(activeShow, 'id')) {
        // Use existing show data
        commit('SET_DRAFT_SHOW', {
          id: activeShow.id,
          title: activeShow.title || '',
          description: activeShow.description || '',
          square_image: activeShow.square_image || '',
          rectangle_image: activeShow.rectangle_image || '',
          author: activeShow.author || '',
          email: activeShow.email || '',
          itunes_categories: activeShow.itunes_categories || '',
          language_override: activeShow.language_override || '',
          podcast_link: activeShow.podcast_link || '',
          rss_explicit: activeShow.rss_explicit || false,
          show_format: activeShow.show_format || 'episodic',
          social_media_connections: activeShow.social_media_connections || [],
          playlist_categories: activeShow.playlist_categories || [],
        });
      } else {
        // New show with default state
        commit('SET_DRAFT_SHOW', {
          description: '',
          square_image: '',
          rectangle_image: '',
          title: '',
          author: '',
          email: '',
          itunes_categories: '',
          language_override: 'en',
          podcast_link: '',
          rss_explicit: false,
          show_format: 'episodic',
          social_media_connections: [],
          playlist_categories: [],
          // the following aren't currently set by the show creation wizard,
          // they're here to make the backend accept the new show
          post_bumper: null,
          pre_bumper: null,
          parent: null,
          schedule: [],
        });
      }
    },

    updateDraftShow({ commit }, show) {
      commit('UPDATE_DRAFT_SHOW', show);
    },

    async getSMCSubtypes({ dispatch, commit, rootState }) {
      await handleAjax({
        request: http.get(`${rootState.CreateAndPublishStore.endpoint}/rsssubtype/`),
        dispatch,
        commit,
        mutation: 'SET_RSS_SUBTYPES',
        errmsg: 'Unable to talk to server to fetch RSS subtypes',
      });
    },

    async addSocialMediaConnection({ dispatch, commit, rootState }, conn) {
      await handleAjax({
        request: http.post(`${rootState.CreateAndPublishStore.endpoint}/socialmediaconnection/`, conn),
        dispatch,
        commit,
        errmsg: 'Unable to talk to server to add social media connection',
        callback(err, data) {
          if (!err) {
            dispatch('CreateAndPublishStore/getSocialMediaConnections', null, { root: true });
            if (data.type === 'rss') {
              dispatch('regenerateRSSFeeds', [data.id]);
            }
          }
        },
      });
    },

    async editSocialMediaConnection({ dispatch, commit, rootState }, conn) {
      await handleAjax({
        request: http.put(`${rootState.CreateAndPublishStore.endpoint}/socialmediaconnection/${conn.id}/`, conn),
        dispatch,
        commit,
        mutation: 'CreateAndPublishStore/UPDATE_SOCIAL_MEDIA_CONNECTION',
        root: true,
        errmsg: 'Unable to talk to server to update a social media connection',
        callback(err, data) {
          if (!err) {
            if (data.type === 'rss') {
              dispatch('regenerateRSSFeeds', [data.id]);
            }
          }
        },
      });
    },

    async deleteSocialMediaConnection({ dispatch, commit, rootState }, conn) {
      await handleAjax({
        request: http.delete(`${rootState.CreateAndPublishStore.endpoint}/socialmediaconnection/${conn.id}`),
        dispatch,
        commit,
        mutation: 'CreateAndPublishStore/DELETE_SOCIAL_MEDIA_CONNECTION',
        root: true,
        modify() {
          return conn;
        },
        errmsg: 'Unable to talk to server to delete a social media connection',
      });
    },

    async addShow({ dispatch, commit }, show) {
      // first we POST to create a 'placeholder' so that we get the ID
      const newlyCreatedShowPlaceholder = await new CreateAndPublishService().create(show);

      // then we PUT using that ID (if successful)
      // this is when we populate the new show with the form values
      if (newlyCreatedShowPlaceholder?.id) {
        const newShow = await new CreateAndPublishService().edit({
          ...newlyCreatedShowPlaceholder, // spreading to get all fields to prevent a 400
          ...show, // form values
          id: newlyCreatedShowPlaceholder.id,
        });

        commit('CreateAndPublishStore/SET_CURRENT_SHOW', newShow, { root: true });
        commit(
          'CreateAndPublishStore/SET_ALERT_MESSAGE',
          {
            type: 'success',
            header: 'Success!',
            message: "You've created a new show. Click the New Episode button below to get started.",
          },
          { root: true }
        );
        commit('CreateAndPublishStore/SET_SHOULD_SHOW_ALERT', true, { root: true });
        dispatch('CreateAndPublishStore/fetchAllShowsData', null, { root: true });
      }
    },

    async updateShow({ dispatch, commit, rootState }, show) {
      const { id } = show;
      await handleAjax({
        request: http.put(`${rootState.CreateAndPublishStore.endpoint}/show/${id}/`, show),
        dispatch,
        commit,
        callback(err, data) {
          if (!err) {
            commit('CreateAndPublishStore/SET_CURRENT_SHOW', data, { root: true });
          }
        },
        errmsg: 'Unable to talk to server to update show',
      });
    },

    async regenerateRSSFeeds({ dispatch, commit, rootGetters }, smcIds) {
      const station = rootGetters['CreateAndPublishStore/settings'].station_name.toLowerCase();
      const payload = {
        job_type: 'static_rss',
        rss_smcs: smcIds,
        station,
      };

      await handleAjax({
        request: Vue.http.post(`${rootGetters['CreateAndPublishStore/rssImportUrl']}/${station}/api/v2/pw/`, payload),
        // request: Vue.http.post(`${rootState.CreateAndPublishStore.endpoint}/import-rss/`, payload),
        dispatch,
        commit,
      });
    },
  },
};
