// Roles & Permissions
const adminOnly = { AdminOnly: true };
const allowAnonymousMeta = { AllowAnonymous: true };
const preventAuthenticatedMeta = { PreventAuthenticated: true };
const preventDisabled = { PreventDisabled: true };
const requiresBrandAccess = { RequiresBrandAccess: true };
const requiresMultiBrandAccount = { RequiresMultiBrandAccount: true };
const requiresProductAccess = { RequiresProductAccess: true };
const requiresSingleBrandAccount = { RequiresSingleBrandAccount: true };
const requiresValidSubscription = { RequiresValidSubscription: true };

export {
  adminOnly,
  allowAnonymousMeta,
  preventAuthenticatedMeta,
  preventDisabled,
  requiresBrandAccess,
  requiresMultiBrandAccount,
  requiresProductAccess,
  requiresSingleBrandAccount,
  requiresValidSubscription,
};
