import { GetMyProfileResponse } from '@/services/MyProfileService';
import MyProfileService from '@/services/MyProfileService';
import type { Module } from 'vuex';
import type { RootState } from '@/types/store';

const fallbackECToken =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ2ZXJzaW9uIjoiMi4wIiwidXNlcl9uYW1lIjoiZ2VvcmdlLmthbmNoZXYrMTQxQHJ0c2xhYnMuY29tIiwidXNlcl9wYXNzIjoiNDBhMDEyN2IwYzE2M2Y2ZTcxMThiMTgxNTc3MGVkMTM0MTNhOGVmOSIsImFjY291bnRDb2RlIjoiZ2VvcmdlLmthbmNoZXYrMTQxQHJ0c2xhYnMuY29tIiwiaW50ZXJuYWwiOmZhbHNlLCJBVVRIX1NUQVRVUyI6dHJ1ZSwiaXNBcGkiOnRydWUsImlhdCI6MTY0NTcxMDY5MiwiZXhwIjoxNjQ1Nzk3MDkyfQ.zhHAVfQeBLrZQnhXaplEYYQBe7iKoPk0BEe9VjANKTs';

export interface CurrentUserState {
  data: GetMyProfileResponse | null;
  error: string | null;
  loading: boolean;
}

export const initialState: CurrentUserState = {
  data: null,
  error: null,
  loading: false,
};

const userService = new MyProfileService();

const mutations = {
  setUser: (state: CurrentUserState, user: GetMyProfileResponse) => {
    if (user?.email) {
      // check for data before storing
      state.data = {
        ...state.data, // avoid overriding existing data
        ...user,
        ecId: user.ecId || fallbackECToken, // fallback if a user doesn't have token
      };
    }
  },
  setError: (state: CurrentUserState, error: string | null) => {
    state.error = error;
  },
  setLoading: (state: CurrentUserState, loading: CurrentUserState['loading']) => {
    state.loading = loading;
  },
};

const actions = {
  async requestUser({ commit }) {
    commit('setLoading', true);
    const user = await userService.getMyProfile();
    if (user?.id) {
      commit('setUser', user);
      commit('setAuthenticatedUser', user, { root: true });
    } else {
      commit('setError', 'There was an error retrieving your user.');
    }
    commit('setLoading', false);
  },
  async updateUser({ commit }, data) {
    if (data.firstName) {
      data.firstName = data.firstName.trim();
    }

    if (data.lastName) {
      data.lastName = data.lastName.trim();
    }

    return userService.updateMyProfile(data).then((res) => {
      if (res.errors) {
        throw res.errors;
      } else {
        commit('setUser', res);
        commit('setAuthenticatedUser', res, { root: true });
        return res;
      }
    });
  },
};

const currentUserModule: Module<CurrentUserState, RootState> = {
  namespaced: true,
  state: () => ({ ...initialState }),
  actions,
  getters: {
    error: ({ error }: CurrentUserState) => error,
    getECId: (state: CurrentUserState) => state.data?.ecId || fallbackECToken,
    user: ({ data }: CurrentUserState) => data,
  },
  mutations,
};

const moduleName = 'currentUser';

export default currentUserModule;

export { moduleName };
