// 8/30/21 don't use for now
export default class Page {
  content;
  totalElements: number;

  constructor(content, totalElements: number) {
    this.content = content;
    this.totalElements = totalElements;
  }
}
