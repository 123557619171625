<template>
  <button class="close-button material-icons" @click="$emit('click', $event)">close</button>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: var(--neutral-grey-1-text);
  background-color: transparent;
  box-shadow: none;
  border: none;
  padding: 0;

  &:hover {
    box-shadow: 0px 1px 8px #22204633 !important;
  }

  &:focus {
    outline: 4px solid #00c6c680 !important;
  }

  &:focus:not(.focus-visible) {
    outline: none !important;
  }

  &:focus-visible {
    outline: 4px solid #00c6c680 !important;
  }
}
</style>
