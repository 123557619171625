import getScript from 'getscript-promise';
import store from '@/store';

const run = () => {
  // Gets TwistedWave sdk
  getScript(`${location.protocol}//www.twistedwave.com/online/sdk.js`).catch((err) => {
    console.error(err);
    store.commit('CreateAndPublishStore/SET_MESSAGE', {
      name: 'TwistedWave',
      details: `Couldn't retrieve TwistedWave SDK. Please refresh this page.`,
      type: 'error',
    });
  });
};

export default run;
