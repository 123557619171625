<template>
  <div class="postv2-modal">
    <div class="postv2-modal-backdrop" @click="closeIfAble"></div>

    <div :class="getClassList()">
      <div class="postv2-modal-header">
        <h2 class="postv2-modal-header__heading">
          {{ heading }}
        </h2>
        <div class="postv2-modal-header__close-button" @click="close">
          <img
            width="16"
            class="close-button"
            src="@/assets/createandpublish-assets/create-episode/grey-close.svg"
            alt="Close"
          />
        </div>
      </div>

      <div class="postv2-modal-content" :style="contentStyleOverrides">
        <!--This slot contains the content of the modal -->
        <slot name="content" />
      </div>

      <div class="postv2-modal-footer">
        <!-- This slot should contain actions among other things -->
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
// TODO: Documentation
export default {
  name: 'modal-layout',
  props: {
    heading: String,
    expanding: Boolean /** Removes fixed height from content **/,
    clickOutsideToClose: {
      default: true,
      type: Boolean,
    } /** Allows for preventing close on clicking outside modal **/,
    contentStyleOverrides: Object,
    size: String /** TODO: Implement different sizes */,
  },
  methods: {
    getClassList() {
      return {
        'postv2-modal-container': true,
        'postv2-modal-size--large': this.size === 'LARGE',
        'postv2-modal--expanding': this.expanding,
      };
    },
    close() {
      this.$emit('on:close');
    },
    closeIfAble() {
      if (this.clickOutsideToClose) {
        this.$emit('on:close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/** All numbers are completely arbitrary */
.postv2-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

  .postv2-modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.7);
    content: '';
    z-index: 998;
  }

  .postv2-modal-container {
    width: 500px;
    position: absolute;
    background-color: white;
    padding: 27px 32px;
    z-index: 999;

    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    color: #000;
    margin: 0px auto;
    transition: all 0.3s ease;

    /** Header */
    .postv2-modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 25px;
      border: none;

      .postv2-modal-header__heading {
        margin: 0;
        font-weight: bold;
        font-size: 24px;
      }

      .postv2-modal-header__close-button {
        cursor: pointer;
      }
    }

    /** Content */
    .postv2-modal-content {
      border: none;
      width: 100%;
      height: 200px;
      overflow: auto;
      -ms-overflow-style: scrollbar;
    }

    .postv2-modal-footer {
      border-top: none;
      margin-top: 15px;

      .post-button-green {
        float: right;
        margin: 1em;
        width: auto;
        text-transform: uppercase;
      }
      .post-button-white {
        float: right;
        margin: 1em;
        width: auto;
        text-transform: uppercase;
        border: 1px solid medium-grey;
      }
      .post-button-white::first-letter {
        color: black;
        font-weight: 200;
      }
    }

    &.postv2-modal--expanding {
      max-height: 97vh;
      .postv2-modal-content {
        height: auto;
        max-height: 700px;
      }
    }

    /** Large modifier */
    &.postv2-modal-size--large {
      width: 90%;
      max-width: 1140px;

      .postv2-modal-content {
        height: 375px;
        overflow: auto;
        -ms-overflow-style: scrollbar;
      }
    }
  }
}
</style>
