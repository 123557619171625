import { Feature } from '@/types/features/BEFeature';
/**
 * Transform BE features to match FE expected structure
 * @param payload
 * @returns
 */
export const mapFeatures = (payload: Feature[] = []) => {
  // Expect to parse API data
  return payload.map((feature) => {
    if (feature.enabled) {
      return feature.name;
    }
  });
};
