import type { AudiencePublicProfileData } from 'content-cloud-types/dist/types/audience/ProfileData';
import { SocialRankingInterval } from 'content-cloud-types/dist/types/audience/SocialRanking';

export enum SocialRankingContext {
  total_reach = 'total_reach',
  growth_rate = 'growth_rate',
}

/**
 * Leaderboard data by ranking interval (day, week, month)
 */
export type LeaderboardsByInterval = {
  [rankingInterval in SocialRankingInterval]?: LeaderboardsByContext;
};

/**
 * Leaderboard data by ranking context (total_growth, growth_rate)
 */
export type LeaderboardsByContext = {
  [context in SocialRankingContext]: LeaderboardRankData[];
};

/**
 * Leaderboard metrics about a specific entry.
 */
export interface LeaderboardRankData {
  value: LeaderboardRankDataValueMetric;
  rank: LeaderboardRankDataRankMetric;
  profile?: AudiencePublicProfileData;
}

/**
 * The value used to rank an entity in a leaderboard
 */
export interface LeaderboardRankDataValueMetric {
  current?: number;
  previous?: number;
  percentChange?: number;
}

/**
 * The actual rank of an entity in a leaderboard
 */
export interface LeaderboardRankDataRankMetric {
  current?: number;
  previous?: number;
  rankDifference?: number;
}

/**
 * Models the data structure used by LeaderboardRankingListItem.vue
 */
export interface LeaderboardRankProfile {
  id: number;
  name: string;
  image: string;
  numLinkedAccounts: number;
  rank: number;
  percentChange: number;
  isSelf: boolean;
}
