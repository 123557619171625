export default {
  dashboard: 'Dashboard',
  youTube: 'YouTube',
  instagram: 'Instagram',
  facebook: 'Facebook',
  twitter: 'Twitter',
  tikTok: 'TikTok',
  linkedIn: 'LinkedIn',
  pinterest: 'Pinterest',
  webTraffic: 'Web traffic',
  leaderboard: 'Leaderboard',
  creatorCommunity: 'Creator Community',
  settings: 'Settings',
};
