import Vue from 'vue';
import moment from 'moment';
import 'moment-duration-format';

// truncate the value passed to our function
// by the length specified
// Ex {{ 'Here is a string' | truncate '3' }}
Vue.filter('truncate', (value, length) => value.substring(0, length));

// calculate our day label based off of the
// beginning of the week and the index of
// our v-for loop and format appropriately
// the $index arg is optional
// Ex {{ dayLabel | calcDayLabel $index }}
Vue.filter('calcDayLabel', (value) => value.substring(0, 3));

// calculate our day number based off of the
// beginning of the week and the index of
// our v-for loop and format appropriately
// the $index arg is optional
// Ex {{ dayNumber | calcDayNumber $index }}
Vue.filter('calcDayNumber', (value, index) => moment(value).add(index, 'days').format('D'));

/**
 * Provides a formatted date/time.
 * @param {Number} epoch Unix timestamp
 * @param {String} timeZone Default is America/New_York
 * @returns {String} the date/time formatted as MM/DD/YYYY, H:MM A
 */
export function formatDateTime(epoch, timeZone = 'America/New_York') {
  const dateTime = new Date(epoch * 1000);
  // These are split up because the formatter can't do what the design calls for when combined.
  const date = dateTime.toLocaleDateString('en-US', { timeZone });
  const time = dateTime.toLocaleTimeString('en-US', { timeZone, timeStyle: 'short' });
  return `${date}, ${time}`;
}

/**
 * Provides a formatted date/time in a longer form.
 * @param {Number} epoch Unix timestamp
 * @param {String} timeZone Default is America/New_York
 * @returns {String} the date/time formatted as Month D, YYYY, HH:MM A
 */
export function formatDateTimeLong(epoch, timeZone = 'America/New_York') {
  const dateTime = new Date(epoch * 1000);
  // These are split up because the formatter can't do what the design calls for when combined.
  const date = dateTime.toLocaleDateString('en-US', { timeZone, dateStyle: 'long' });
  const time = dateTime.toLocaleTimeString('en-US', { timeZone, timeStyle: 'short' });
  return `${date}, ${time}`;
}

/**
 * Provides a formatted date.
 * @param {Number} epoch Unix timestamp
 * @param {String} timeZone Default is America/New_York
 * @returns {String} the date formatted as MM/DD/YYYY
 */
export function formatDate(epoch, timeZone = 'America/New_York') {
  return new Date(epoch * 1000).toLocaleDateString('en-US', { timeZone });
}

/**
 * Provides a formatted date with a 2-digit year value.
 * @param {Number} epoch Unix timestamp
 * @param {String} timeZone Default is America/New_York
 * @returns {String} the date formatted as MM/DD/YY
 */
export function formatDateShortYear(epoch, timeZone = 'America/New_York') {
  return new Date(epoch * 1000).toLocaleDateString('en-US', {
    timeZone,
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });
}

/**
 * Provides a formatted time.
 * @param {Number} epoch Unix timestamp
 * @param {String} timeZone Default is America/New_York
 * @returns {String} the time formatted as H:MM A
 */
export function formatTime(epoch, timeZone = 'America/New_York') {
  return new Date(epoch * 1000).toLocaleTimeString('en-US', { timeZone, timeStyle: 'short' });
}

/**
 * Provides a formatted duration.
 * @param {Number} secs Number of seconds to format
 * @returns {String} the duration formatted as H [Hrs] m [Mins]; truncates hrs if 0
 */
export function formatDuration(secs) {
  if (secs < 60) return '1 Mins'; // To prevent showing 0 Mins
  return moment.duration(secs, 'seconds').format('h [Hrs] m [Mins]', {
    stopTrim: 'm',
    forceLength: true,
  });
}

/**
 * Provides a briefer formatted duration.
 * @param {Number} secs Number of seconds to format
 * @returns {String} the duration formatted as hh:mm:ss; truncates hrs if 0
 */
export function formatDurationBrief(secs) {
  return moment.duration(secs, 'seconds').format('hh:mm:ss ', {
    stopTrim: 'mm',
    forceLength: true,
  });
}
