import HttpClient from '@/services/common/HttpClient';
import DiscoverCategoriesModel from '@/models/DiscoverCategoriesModel';
import TrendingSourcesModel from '@/models/TrendingSourcesModel';
import DiscoverShortcutModel from '@/models/DiscoverShortcutModel';

export default class DiscoverCategoryService {
  private http: HttpClient = HttpClient.getInstance();
  private endpoint = `${process.env.VUE_APP_API_URL}/discover`;

  public async getAll(): Promise<DiscoverCategoriesModel> {
    return this.http.get(`${this.endpoint}/categories`);
  }

  public async getCategories(
    type?: 'content' | 'designated-market-area'
  ): Promise<{ id: number; name: string; isDemographic: boolean }[]> {
    return this.http.get(`${this.endpoint}/settings/categories${type ? '?type=' + type : ''}`);
  }

  public async fetchShortcutsPreview(data: {
    categoryIds: number[];
    dma?: number;
    age?: string;
    gender?: 'M' | 'F' | null;
  }): Promise<
    | {
        categoryIds: number[];
        name: string;
        displayName: string;
        isViral?: boolean;
        gender?: 'M' | 'F' | null;
        age?: string;
      }[]
    | Error
  > {
    if (data.categoryIds.length < 1) {
      throw new Error('Must provide at least one category ID');
    }

    const url = Object.keys(data).reduce((url: URL, objKey: string) => {
      if (!data[objKey]) {
        return url;
      }

      if (Array.isArray(data[objKey])) {
        url.searchParams.set(objKey, data[objKey].join(','));
      } else {
        url.searchParams.set(objKey, data[objKey]);
      }

      return url;
    }, new URL(`${this.endpoint}/settings/shortcuts`));

    return this.http.get(url.toString());
  }

  public async sendTrendingSources(data: TrendingSourcesModel): Promise<TrendingSourcesModel> {
    return this.http.post(`${this.endpoint}/shortcuts`, data);
  }

  public async getShortcuts(brandId: number): Promise<DiscoverShortcutModel[]> {
    return this.http.get(`${this.endpoint}/${brandId}/settings/shortcuts`);
  }

  public async getDiscoverSettings(brandId: number) {
    return this.http.get(`${this.endpoint}/${brandId}/settings/brand-group`);
  }
}
