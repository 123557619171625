const Dashboard = {
  Billing: 'billing',
  BrandsCampaignsCard: 'brandsCampaignCard',
  CreateProduct: 'createProduct',
  Dashboard: 'dashboard',
  DiscoverProduct: 'discoverProduct',
  UserAccountCard: 'userAccountCard',
  WelcomeHeader: 'welcomeHeader',
};

const Login = {
  Login: 'login',
  UserConfirmationNewPassword: 'userConfirmationNewPassword',
};

const Users = {
  AddEditUser: 'addEditUser',
  Users: 'users',
  UsersTable: 'usersTable',
};

const Campaigns = {
  AddEditCampaigns: 'addEditCampaigns',
  CampaignsTable: 'campaignsTable',
};

const Brands = {
  AddEditBrands: 'addEditBrands',
  BrandsTable: 'brandsTable',
};

const Discover = {
  CollapsibleSidebar: 'collapsibleSidebar',
  WelcomeBanner: 'WelcomeBanner',
  Trending: 'Trending',
  IdeaStarters: 'IdeaStarters',
  TrendingCardGrid: 'TrendingCardGrid',
  TrendingCardList: 'TrendingCardList',
  SettingsCard: 'SettingsCard',
  KnowledgeBaseCard: 'KnowledgeBaseCard',
  IdeaStarterCard: 'IdeaStarterCard',
  CopyToClipboard: 'CopyToClipBoard',
  TrendingDrawer: 'TrendingDrawer',
  IdeaStarterDrawer: 'IdeaStarterDrawer',
};

const CreateAndPublish = {
  CreateSomethingNew: 'CreateSomethingNew',
};

const Subscriptions = {
  Subscriptions: 'subscriptions',
};

const Components = {
  ...Brands,
  ...Campaigns,
  ...CreateAndPublish,
  ...Dashboard,
  ...Discover,
  ...Login,
  ...Subscriptions,
  ...Users,
  UiKit: 'UiKit',
};

const ElementType = {
  Button: 'button',
  Card: 'card',
  Checkbox: 'checkbox',
  Component: 'component',
  Drawer: 'drawer',
  Icon: 'icon',
  Image: 'image',
  Link: 'link',
  MultiSelect: 'multiSelect',
  Page: 'page',
  Panel: 'panel', // Something like Welcome Header
  Radio: 'radio',
  SearchBar: 'searchBar',
  Section: 'section', // Any section like `Set up your account`
  SelectInput: 'selectInput',
  TableCell: 'tableCell',
  TableRow: 'tableRow',
  Tabs: 'tabs',
  Text: 'text',
  TextInput: 'textInput',
  Title: 'title', // Of a drawer, a section, a card, etc
  Toggle: 'toggle',
  View: 'view', // The outermost element in a view
};

export default {
  install(Vue) {
    // This function can be called globally for the data-qa attribute
    // Example:
    // $getComponent('UsersTable', 'TableCell', 'name')
    // returns 'userTable-tableCell-name'

    Vue.prototype.$getComponent = function (component, element, name) {
      return Components[component] + '-' + ElementType[element] + '-' + name;
    };
  },
};
