import { bestPracticesUrlPrefix } from './util';
import type { RootState } from '@/types/store';
import type { GetterTree } from 'vuex';
import type { CreateAndPublishStoreState } from '@/createandpublish/core/store';

export default {
  // Welcome banner visible
  isWelcomeBannerVisible: (state) => state.welcomeBannerActive,

  // Bulk download jobs
  bulkDownloadJobs: (state) => state.bulkDownloadJobs || [],

  message: (state) => state.message,

  station: (state) => state.station,

  station_name: (state) => String(state.settings.station_name).toLowerCase(),

  stations: (state) => state.settings.stations,

  stationList: (state) => state.stationList,

  // Brand timezone.
  timeZone: (state) => state.settings?.timezone || 'America/New_York',

  // Shared
  previewPlayer: (state) => state.previewPlayer,
  showScheduleModal: (state) => state.showScheduleModal,

  // Shows and episodes data
  allShows: (state) => state.shows,
  unfilteredShows: (state) => state.showsWithTitles || [],
  showsAvailable: (state) => state.showsWithTitles,

  activeShowEpisodes: (state) => state.activeShowEpisodes,

  // TODO: Make this use a cache object and the current route
  activeShow: (state) => state.activeShow,

  hasActiveShow: (state) => !!state.activeShow,

  allSocialMediaConnections: (state) => state.socialMediaConnections,

  // Audio Builder
  uncompiledEvents: (state) => state.buildAudioData.uncompiledList,

  // Logger
  allLoggerEvents: (state) => state.loggerEvents,
  groups: (state) => state.groups,
  colorMap: (state) => state.colorMap, // Don't use this
  groupCategories: (state) => {
    const mappedGroups = {};

    if (!state.groups) {
      return mappedGroups;
    }

    state.groups.forEach((group) => {
      mappedGroups[group.name] = {
        categoryType: group.category_type,
        color: group.color,
        categories: group.categories.map((category) => category.name),
      };
    });

    return mappedGroups;
  },
  categoryGroups: (state) => {
    // This is ugly, but the only way I can see this working...
    const mappedCategories = {};

    if (!state.groups) {
      return mappedCategories;
    }

    state.groups.forEach((group) => {
      group.categories.forEach((category) => {
        mappedCategories[category.name] = {
          group: group,
        };
      });
    });

    return mappedCategories;
  },
  categoryMap: (state) => state.categoryMap,
  twModal: (state) => state.twModal,

  // Settings
  settings: (state) => state.settings,
  twistedWaveHost: (state) => (state.settings && state.settings.twisted_wave_server) || '',

  stationBrandId: (state) => state.stationBrandId,

  // SSE
  msgServer: (state) => state.msgServer,
  stationSessionId: (state) => state.stationSessionId,

  // Playlist Categories (tags)
  playlistCategories: (state) => state.playlistCategories,
  stationUrl: (state) => {
    const loc = process.env.NODE_ENV !== 'production' ? 'https:' : location.protocol;
    if (state.settings.media_endpoint) {
      return `${loc}//${state.settings.media_endpoint.replace('media.', '')}/${state.station_name}/`;
    }
  },
  mediaUrl: (state) => {
    const loc = process.env.NODE_ENV !== 'production' ? 'https:' : location.protocol;
    if (state.settings.media_endpoint) {
      return `${loc}//${state.settings.media_endpoint}`;
    }
  },
  progressUrl: (state) => {
    const loc = process.env.NODE_ENV !== 'production' ? 'https:' : location.protocol;
    if (state.settings.media_endpoint) {
      return `${loc}//${state.settings.media_endpoint}`;
    }
  },
  eventsUrl: (state) => {
    const loc = process.env.NODE_ENV !== 'production' ? 'https:' : location.protocol;
    if (state.settings.events_endpoint) {
      return `${loc}//${state.settings.events_endpoint}`;
    }
  },
  rssImportUrl: (state) => {
    if (state.settings.rss_import_api_prefix) {
      return `${state.settings.rss_import_api_prefix}`;
    }
    return state.baseUri;
  },
  tags: (state) => state.playlistCategories,
  getShowData: (state) => state.activeShow,
  navQLinks: (state) => state.navQLinks,
  username: (state) => state.settings && state.settings.username,
  email: (state) => state.settings && state.settings.email,
  existing_images: (state) => state.existing_images,
  isPodcastOnlyUser: (state) => state.isPodcastOnlyUser,
  isAdmin: (state) => state.isAdmin,
  isP4pPremium: (state) => state.settings.p4p_premium,
  isLoggerOnlyUser: (state) => state.isLoggerOnlyUser,
  isAvess: (state) => state.isAvess,
  stationTrackingPrefixList: (state) => {
    const currentStationName = state.settings.station_name.toLowerCase();
    const currentStationObj = state.settings.station_list.find(
      (station) => station.station.toLowerCase() === currentStationName
    );
    return (currentStationObj && currentStationObj.prefix_list) || [];
  },
  activeShowTrackingPrefixList: (state) => {
    const stringifiedPrefixList = state.activeShow.prefix_list;
    if (!stringifiedPrefixList) return [];

    try {
      return JSON.parse(stringifiedPrefixList);
    } catch (err) {
      return [];
    }
  },
  bestPracticesArticles: (state) => {
    if (state.bestPracticesManifest.articles) {
      return state.bestPracticesManifest.articles.map(({ title, description, image, resource_location }) => {
        return {
          title,
          description,
          image: image ? `${bestPracticesUrlPrefix}${image}` : '',
          resourceLocation: resource_location ? `${bestPracticesUrlPrefix}${resource_location}` : '',
        };
      });
    }
    return [];
  },
  bestPracticesVideos: (state) => {
    if (state.bestPracticesManifest.videos) {
      return state.bestPracticesManifest.videos.map(({ title, description, image, resource_location }) => {
        return {
          title,
          description,
          image: image ? `${bestPracticesUrlPrefix}${image}` : '',
          resourceLocation: resource_location ? `${bestPracticesUrlPrefix}${resource_location}` : '',
        };
      });
    }
    return [];
  },
  bestPracticesLinks: (state) => {
    if (state.bestPracticesManifest.links) {
      return state.bestPracticesManifest.links.map(({ title, description, image, resource_location }) => {
        return {
          title,
          description,
          image: image ? `${bestPracticesUrlPrefix}${image}` : '',
          resourceLocation: resource_location || '',
        };
      });
    }
    return [];
  },
  features: (state) => {
    try {
      return JSON.parse(state.settings.features);
    } catch (err) {
      return {};
    }
  },
  facebook_app_id: (state) => state.settings && state.settings.facebook_app_id,
  hideLogger: (state) => state.isPodcastOnlyUser === true || state.isAvess === true,
  endpoint: (state) => state.endpoint,
  shouldShowAlert: (state) => state.shouldShowAlert,
  alertMessage: (state) => state.alertMessage,
} as GetterTree<CreateAndPublishStoreState, RootState>;
