import HttpClient from '@/services/common/HttpClient';
import {
  AnalyticsRequest,
  ShowAnalyticsData,
  EpisodeAnalyticsData,
  CountryAnalyticsData,
  PlatformAnalyticsData,
  EmpireCollectionIdToShowNameMapData,
  EmpireEpisodeIdToPodcastEpisodeMapRequest,
  EmpireEpisodeIdToPodcastEpisodeMapData,
  AnalyticsCSVResponse,
} from '@/types/createandpublish/analytics/PodcastAnalytics';
import store from '@/store';

export default class CreateAndPublishAnalyticsService {
  private http: HttpClient = HttpClient.getInstance();

  private get brandId() {
    const brandId = store?.getters['selectedBrand']?.id;
    if (!brandId) {
      // Fallback to localStorage
      const _store = JSON.parse(JSON.stringify(localStorage.getItem('vuex')));
      const brand = JSON.parse(_store)?.BrandStore.selectedBrand;
      return brand.id;
    }
    return brandId;
  }

  /**
   * Processes analytics data payload from an object into a query string.
   */
  private getQueryStringFromAnalyticsRequestPayload(payload: AnalyticsRequest): string {
    const query = new URLSearchParams();
    for (const [key, value] of Object.entries(payload)) {
      // Add to query if not falsy
      if (value) query.set(key, value);
    }
    // If no keys, return an empty string, otherwise return the query string.
    return query.keys().next()?.done ? '' : `?${query.toString()}`;
  }

  // Endpoint prefix definitions.

  private get podcastAnalyticsEndpoint() {
    return `/createandpublish/${this.brandId}/analytics/podcasts`;
  }
  private get usageStatisticsEndpoint() {
    // Not yet scoped.
    return '';
  }

  // General analytics endpoints to help fill in podcast data not provided
  // by POST Analytics CDN. 'Empire' is the name of the CDN.

  public getEmpireCollectionIdToShowMap(): Promise<EmpireCollectionIdToShowNameMapData> {
    // Note: this uses non-standard API prefix.
    return this.http.get(`createandpublish/${this.brandId}/shownames/`);
  }

  public getEmpireEpisodeIdToPodcastEpisodeMap(
    payload: EmpireEpisodeIdToPodcastEpisodeMapRequest
  ): Promise<EmpireEpisodeIdToPodcastEpisodeMapData> {
    return this.http.post(`createandpublish/${this.brandId}/episodenames/`, payload);
  }

  // Endpoints for getting analytics data by type from POST Analytics CDN.

  public getPodcastShowAnalytics(payload: AnalyticsRequest): Promise<ShowAnalyticsData> {
    const queryString = this.getQueryStringFromAnalyticsRequestPayload(payload);
    return this.http.get(`${this.podcastAnalyticsEndpoint}/shows/${queryString}`);
  }

  public getPodcastEpisodeAnalytics(payload: AnalyticsRequest): Promise<EpisodeAnalyticsData> {
    const queryString = this.getQueryStringFromAnalyticsRequestPayload(payload);
    return this.http.get(`${this.podcastAnalyticsEndpoint}/episodes/${queryString}`);
  }

  // Regions aren't used, but putting it here in case it's ever needed.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getPodcastRegionAnalytics(payload: AnalyticsRequest): Promise<any> {
    const queryString = this.getQueryStringFromAnalyticsRequestPayload(payload);
    return this.http.get(`${this.podcastAnalyticsEndpoint}/region/${queryString}`);
  }

  public getPodcastCountryAnalytics(payload: AnalyticsRequest): Promise<CountryAnalyticsData> {
    const queryString = this.getQueryStringFromAnalyticsRequestPayload(payload);
    return this.http.get(`${this.podcastAnalyticsEndpoint}/country/${queryString}`);
  }

  public getPodcastPlatformAnalytics(payload: AnalyticsRequest): Promise<PlatformAnalyticsData> {
    const queryString = this.getQueryStringFromAnalyticsRequestPayload(payload);
    return this.http.get(`${this.podcastAnalyticsEndpoint}/platform/${queryString}`);
  }

  // Endpoints for requesting CSVs for analytics data by type.

  public getPodcastShowAnalyticsCSV(payload: AnalyticsRequest): Promise<AnalyticsCSVResponse> {
    const queryString = this.getQueryStringFromAnalyticsRequestPayload(payload);
    return this.http.get(`${this.podcastAnalyticsEndpoint}/shows/csv/${queryString}`);
  }

  public getPodcastEpisodeAnalyticsCSV(payload: AnalyticsRequest): Promise<AnalyticsCSVResponse> {
    const queryString = this.getQueryStringFromAnalyticsRequestPayload(payload);
    return this.http.get(`${this.podcastAnalyticsEndpoint}/episodes/csv/${queryString}`);
  }

  public getPodcastRegionAnalyticsCSV(payload: AnalyticsRequest): Promise<AnalyticsCSVResponse> {
    const queryString = this.getQueryStringFromAnalyticsRequestPayload(payload);
    return this.http.get(`${this.podcastAnalyticsEndpoint}/region/csv/${queryString}`);
  }

  public getPodcastCountryAnalyticsCSV(payload: AnalyticsRequest): Promise<AnalyticsCSVResponse> {
    const queryString = this.getQueryStringFromAnalyticsRequestPayload(payload);
    return this.http.get(`${this.podcastAnalyticsEndpoint}/country/csv/${queryString}`);
  }

  public getPodcastPlatformAnalyticsCSV(payload: AnalyticsRequest): Promise<AnalyticsCSVResponse> {
    const queryString = this.getQueryStringFromAnalyticsRequestPayload(payload);
    return this.http.get(`${this.podcastAnalyticsEndpoint}/platform/csv/${queryString}`);
  }
}
