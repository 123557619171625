import type { Plugin, LegendElement, ArcProps } from 'chart.js';
import { getMemoizedImage } from '@/audience/data/chart-plugins/util';
import { PlatformLabels } from '@/audience/data/socialPlatformChartConfig';

import YouTubeIcon from '@/assets/audience/dashboard-doughnut-chart/logo-youtube.svg';
import FacebookIcon from '@/assets/audience/dashboard-doughnut-chart/logo-facebook.svg';
import InstagramIcon from '@/assets/audience/dashboard-doughnut-chart/logo-instagram.svg';
import LinkedInIcon from '@/assets/audience/dashboard-doughnut-chart/logo-linkedin.svg';
import PinterestIcon from '@/assets/audience/dashboard-doughnut-chart/logo-pinterest.svg';
import TikTokIcon from '@/assets/audience/dashboard-doughnut-chart/logo-tiktok.svg';
import TwitterIcon from '@/assets/audience/dashboard-doughnut-chart/logo-twitter.svg';

const getYouTubeIcon = getMemoizedImage(YouTubeIcon);
const getFacebookIcon = getMemoizedImage(FacebookIcon);
const getInstagramIcon = getMemoizedImage(InstagramIcon);
const getLinkedInIcon = getMemoizedImage(LinkedInIcon);
const getPinterestIcon = getMemoizedImage(PinterestIcon);
const getTikTokIcon = getMemoizedImage(TikTokIcon);
const getTwitterIcon = getMemoizedImage(TwitterIcon);

const annulusSectorImagePlugin: Plugin = {
  id: 'annulusSectorImagePlugin',
  afterDatasetDraw: (chart) => {
    const datasetCount = chart.getVisibleDatasetCount();
    if (datasetCount !== 1) throw new Error(`annulusSegImagePlugin expected 1 dataset, got ${datasetCount}`);

    const {
      ctx,
      chartArea: { top, bottom, left, right },
      legend,
    } = chart;

    const datasetMeta = chart.getDatasetMeta(0);

    const cX = (left + right) / 2;
    const cY = (top + bottom) / 2;

    // This is intentionally typed to ArcProps instead of ArcElement
    const arcElements = datasetMeta.data as unknown as ArcProps[];
    const { legendItems } = legend as LegendElement<'doughnut'>;
    const legendItemTitles = legendItems?.map(({ text }) => text) as string[];

    arcElements.forEach((el, i) => {
      const { xOffset, yOffset, hideImage } = ChartPluginHelpers.getRenderingContext(el);
      let getIcon: undefined | (() => Promise<HTMLImageElement>);
      if (!hideImage) {
        switch (legendItemTitles[i]) {
          case PlatformLabels.YOUTUBE:
            getIcon = getYouTubeIcon;
            break;
          case PlatformLabels.FACEBOOK:
            getIcon = getFacebookIcon;
            break;
          case PlatformLabels.INSTAGRAM:
            getIcon = getInstagramIcon;
            break;
          case PlatformLabels.TWITTER:
            getIcon = getTwitterIcon;
            break;
          case PlatformLabels.LINKEDIN:
            getIcon = getLinkedInIcon;
            break;
          case PlatformLabels.PINTEREST:
            getIcon = getPinterestIcon;
            break;
          case PlatformLabels.TIKTOK:
            getIcon = getTikTokIcon;
            break;
          default:
            getIcon = undefined;
            break;
        }

        const halfAverageImgSide: Pixels = 13; // help center the image by offsetting it
        const mX = cX + xOffset - halfAverageImgSide;
        const mY = cY + yOffset - halfAverageImgSide;
        if (getIcon) {
          getIcon().then((image) => {
            ctx.drawImage(image, mX, mY);
          });
        }
      }
    });
  },
};

export default annulusSectorImagePlugin;

class ChartPluginHelpers {
  /**
   * Calculates the the x offset, y offset, and whether or not there is enough space
   * for an icon inside an annulus (the plane between the inner and outer radii
   * of a doughnut).
   * @param {ArcProps} arcElement an annulus sector of a doughnut chart
   * @returns rendering context
   */
  static getRenderingContext(arcElement: ArcProps): {
    xOffset: Pixels;
    yOffset: Pixels;
    hideImage: boolean;
  } {
    const { innerRadius, outerRadius, startAngle, endAngle } = arcElement;
    // The length of any chord within an annulus sector drawn radially
    // from the chart center
    const sideChordLen: Pixels = outerRadius - innerRadius;

    // The length of the annulus' inner arc
    const innerArcLen: Pixels = innerRadius * (endAngle - startAngle);

    // Radius from chart center to midpoint of annulus sector
    // (Imagine anther circle had been drawn between the inner and outer circles)
    const midRadius: Pixels = innerRadius + sideChordLen / 2;

    // Calculate the angle from the chart center whose midpoint is at the center
    // of the annulus sector
    const midAngle: Radians = startAngle + (endAngle - startAngle) * 0.5;

    const xOffset = midRadius * Math.cos(midAngle);
    const yOffset = midRadius * Math.sin(midAngle);
    return {
      xOffset,
      yOffset,
      hideImage: innerArcLen < 22,
    };
  }
}

type Radians = number;
type Pixels = number;
