import Vue from 'vue';
import router from '@/router';

import type { CreateAndPublishStoreState } from './store';
import type { SSE } from '@/types/SSE';
import type { MutationTree } from 'vuex';
import type {
  BestPracticesManifest,
  CategoryGroup,
  MessageBarData,
  PlaylistCategory,
  PlaylistPreview,
  PostAudioEvent,
  Settings,
  Show,
  ShowPreview,
  SocialMediaConnection,
  Station,
  StationOption,
} from '@/types/createandpublish';

export default {
  TOGGLE_WELCOME_BANNER(state, isOpen = true) {
    state.welcomeBannerActive = isOpen;
  },

  SET_PLAYLIST_CATEGORIES(state, playlistCategories: PlaylistCategory[]) {
    state.playlistCategories = playlistCategories;
  },

  ADD_PLAYLIST_CATEGORY(state, playlistCategories: PlaylistCategory) {
    state.playlistCategories.push(playlistCategories);
  },

  UPDATE_PLAYLIST_CATEGORY(state, playlistCategories: PlaylistCategory) {
    const index = state.playlistCategories.findIndex((existingTag) => existingTag.id === playlistCategories.id);
    if (index > -1) {
      state.playlistCategories.splice(index, 1, playlistCategories);
    }
  },

  DELETE_PLAYLIST_CATEGORY(state, id: PlaylistCategory['id']) {
    const index = state.playlistCategories.findIndex((playlistCategories) => playlistCategories.id === id);
    if (index > -1) {
      state.playlistCategories.splice(index, 1);
    }
  },

  SET_ACTIVE_SHOW(state, showId: Show['id']) {
    state.activeShowId = showId ?? 0;
  },

  CLEAR_ACTIVE_SHOW(state) {
    state.activeShow = {} as Show;
  },

  CLEAR_ACTIVE_EPISODES(state) {
    state.activeShowEpisodes = [];
  },

  SET_ACTIVE_EPISODES(state, episodes: PlaylistPreview[]) {
    state.activeShowEpisodes = episodes;
  },

  DELETE_PLAYLIST(state, id: PlaylistPreview['playlist_id']) {
    const index = state.activeShowEpisodes.findIndex((playlist) => id === playlist.playlist_id);
    state.activeShowEpisodes.splice(index, 1);
  },

  SET_MESSAGE(state, message: MessageBarData) {
    state.message = message;
  },

  DISMISS_MESSAGE(state) {
    state.message = null;
  },

  OPEN_PREVIEW_PLAYER(state, { endpoint, data }) {
    state.previewPlayer = {
      endpoint,
      data,
    };
  },
  CLOSE_PREVIEW_PLAYER(state) {
    state.previewPlayer = null;
  },

  // TwistedWaveModal (AudioBuilderModal.vue)
  OPEN_TW_MODAL(state, { eventId }: { eventId: PostAudioEvent['id'] }) {
    state.twModal = { eventId };
  },

  SET_LOGGER_EVENTS(state, events: PostAudioEvent[]) {
    state.loggerEvents = events.filter((event) => event.cart.type_name !== 'SINGLE_USE');
  },

  SET_CATEGORY_GROUPS(state, groups: CategoryGroup[]) {
    state.groups = groups;
  },

  SET_GROUP_MAPS(state) {
    const groups = state.groups;
    const colorMap = {};
    const categoryMap = {};
    groups.forEach((group) => {
      const color = group.color;
      const type = group.name;
      group.categories.forEach((category) => {
        colorMap[category.name] = color;
        switch (type) {
          case 'Promo': // fallthrough
          case 'Commercial': // fallthrough
          case 'Music':
            categoryMap[category.name] = type;
            break;
          default:
            categoryMap[category.name] = 'Talk';
            break;
        }
        // if (type !== 'Promo' && type !== 'Commercial' && type !== 'Music') {
        //   categoryMap[category.name] = 'Talk'
        // } else {
        //   categoryMap[category.name] = type
        // }
      });
    });
    colorMap[''] = 'color-null';
    state.colorMap = colorMap;
    state.categoryMap = categoryMap;
  },

  SET_STATION_SETTINGS(state, { stationBrandId, ...settings }) {
    state.settings = settings as Settings;
    state.stationBrandId = stationBrandId;
    state.station_name = settings.station_name;
    state.settings.role = settings.station_list.find(
      (role: Station) => role.station === settings.station_name.toLowerCase()
    );
    const user = settings.username;
    const key = settings.apikey;
    state.apikeyauth = `ApiKey ${user}:${key}`;
    state.navQLinks = `?username=${user}&api_key=${key}`;

    // station options for station selector
    const stationOpts: StationOption[] = [];
    settings.station_list.forEach((station: Station) => {
      const stationOpt: StationOption = {
        stationTitle: station.station,
        currentUserIsAdmin: station.admin,
        showEditor: station.showEditor,
        defaultImageUrl: station.default_image,
      };
      stationOpts.push(stationOpt);
    });
    state.settings.stations = stationOpts;

    state.isAvess = settings.is_AVESS || false;

    // set a current station by default
    state.selectedStationOption = settings.stations[0];
    state.station = settings.station_name;
  },

  SET_STATION_SESSION_ID(state, sessionId) {
    state.stationSessionId = sessionId;
  },

  ADD_PLAYLIST_ID_FOR_DOWNLOAD(state, { playlistId, type }) {
    const map =
      state.playlistsAwaitingDownload instanceof Map
        ? state.playlistsAwaitingDownload
        : new Map(Object.entries(state.playlistsAwaitingDownload));

    if (map.has(playlistId)) {
      const arrOfTypes = map.get(playlistId);
      arrOfTypes.push(type);
      map.set(playlistId, arrOfTypes);
    } else {
      map.set(playlistId, [type]);
    }

    state.playlistsAwaitingDownload = map;
  },

  REMOVE_PLAYLIST_ID_FOR_DOWNLOAD(state, playlistId) {
    const map =
      state.playlistsAwaitingDownload instanceof Map
        ? state.playlistsAwaitingDownload
        : new Map(Object.entries(state.playlistsAwaitingDownload));

    map.delete(playlistId);

    state.playlistsAwaitingDownload = map;
  },

  SET_PODCAST_ONLY(state) {
    const index = state.settings.station_list.findIndex(
      (station) => state.station?.toLowerCase() === station.station.toLowerCase()
    );
    if (index !== -1) {
      state.isPodcastOnlyUser = state.settings.station_list[index].podcastOnly;
    } else {
      state.isPodcastOnlyUser = false;
    }
  },

  SET_IS_ADMIN(state) {
    const index = state.settings.station_list.findIndex(
      (station) => state.station?.toLowerCase() === station.station.toLowerCase()
    );
    if (index !== -1) {
      state.isAdmin = state.settings.station_list[index].admin;
    } else {
      state.isAdmin = false;
    }
  },

  SET_LOGGER_ONLY(state) {
    const index = state.settings.station_list.findIndex(
      (station) => state.station?.toLowerCase() === station.station.toLowerCase()
    );
    if (index !== -1) {
      state.isLoggerOnlyUser = state.settings.station_list[index].browserOnly;
    } else {
      state.isLoggerOnlyUser = false;
    }
  },

  SET_SOCIAL_MEDIA_CONNECTIONS(state, socialMediaConnections: SocialMediaConnection[]) {
    const smcs = socialMediaConnections;

    // If not empty
    if (smcs.length > 0 && state.showsWithTitles) {
      // Add show data
      smcs.forEach((smc, index) => {
        // List of shows with basic data
        const showsFound: { id: Show['id']; title: Show['title'] }[] = [];
        // Find SMC in shows and add to `showsFound`
        state.showsWithTitles.forEach((show) => {
          if (show.smcs && show.smcs.length > 0) {
            if (show.smcs.findIndex((e) => e.id === smc.id) !== -1) {
              showsFound.push({
                id: show.id,
                title: show.title,
              });
            }
          }
        });
        // Enhance the smc with shows data
        smcs[index] = {
          ...smcs[index],
          shows: showsFound,
        };
      });
    }
    state.socialMediaConnections = smcs;
  },

  SET_CURRENT_SHOW(state, show: Show) {
    if (show.prefix_list && typeof show.prefix_list === 'string') {
      const _prefix_list = JSON.parse(show.prefix_list);
      state.activeShow = {
        ...show,
        prefix_list: _prefix_list,
      };
    } else {
      state.activeShow = show;
    }
  },

  SET_ALL_SHOWS(state, shows: ShowPreview[]) {
    state.showsWithTitles = shows;
  },

  UPDATE_RSS_IMAGE(state, data) {
    state.loadedRSSImage = data.location.replace('.thumbnail.', '.original.');
    if (state.loadedRSSImage.indexOf('http') === -1) {
      state.loadedRSSImage = `https://post.futurimedia.com${state.loadedRSSImage}`;
    }
  },

  APPEND_SOCIAL_MEDIA_CONNECTION(state, smc: SocialMediaConnection) {
    state.socialMediaConnections.push(smc);
  },

  UPDATE_SOCIAL_MEDIA_CONNECTION(state, updatedSmc: SocialMediaConnection) {
    if (state.activeShow.social_media_connections) {
      const smcList = state.activeShow.social_media_connections as SocialMediaConnection[];
      const index = smcList.findIndex(({ id }) => id === updatedSmc.id);
      if (index !== -1) {
        smcList.splice(index, 1, updatedSmc);
      }
    }

    if (state.socialMediaConnections) {
      const smcList = state.socialMediaConnections;
      const index = smcList.findIndex(({ id }) => id === updatedSmc.id);
      if (index !== -1) {
        smcList.splice(index, 1, updatedSmc);
      }
    }
  },

  DELETE_SOCIAL_MEDIA_CONNECTION(state, conn: SocialMediaConnection) {
    const activeShowSMCs = state.activeShow.social_media_connections as SocialMediaConnection[];
    if (activeShowSMCs) {
      const index = activeShowSMCs.findIndex(({ id }) => id === conn.id);
      if (index !== -1) {
        activeShowSMCs.splice(index, 1);
      }
    }

    if (state.socialMediaConnections) {
      const index = state.socialMediaConnections.findIndex(({ id }) => id === conn.id);
      if (index !== -1) {
        state.socialMediaConnections.splice(index, 1);
      }
    }
  },

  UPDATE_PLAYLIST_SETTINGS(state, settings) {
    const playlistId = settings.playlist_id;
    const index = state.activeShowEpisodes.findIndex((episode) => episode.playlist_id === playlistId);
    if (index !== -1) {
      if (settings.unpublished !== undefined) {
        state.activeShowEpisodes[index].unpublished = settings.unpublished;
      }
      if (settings.draft !== undefined) {
        state.activeShowEpisodes[index].draft = settings.draft;
      }
      if (settings.explicit !== undefined) {
        state.activeShowEpisodes[index].explicit = settings.explicit;
      }
    }
  },

  SET_VIDEO_STATUS(state, { playlistId, video_status, advanced_video_status }) {
    const index = state.activeShowEpisodes.findIndex((episode) => episode.playlist_id === playlistId);
    if (index !== -1) {
      if (typeof video_status !== 'undefined') {
        state.activeShowEpisodes[index].video_status = video_status;
      }
      if (typeof advanced_video_status !== 'undefined') {
        state.activeShowEpisodes[index].advanced_video_status = advanced_video_status;
      }
    }
  },

  SET_ADVANCED_VIDEO_PLAYLIST_ID(state, { playlist_id }) {
    state.advancedVideo.playlist_id = Number(playlist_id);
  },

  SET_ADVANCED_VIDEO_EDIT_URL(state, { playlist_id, url }) {
    const currentView = router.currentRoute.name;
    const currentPlaylistId =
      Number(router.currentRoute.query.editingEpisode) || Number(router.currentRoute.params.episodeId);

    if (
      (currentView === 'edit-episode-headliner' || currentView === 'edit-episode-publish') &&
      currentPlaylistId === playlist_id
    ) {
      state.advancedVideo.url = url;
    }
  },

  UPDATE_ADVANCED_VIDEO_STATUS(state, { playlist_id, isCreated, isFailed }) {
    if (state.advancedVideo.playlist_id === playlist_id) {
      if (typeof isCreated !== 'undefined') {
        state.advancedVideo.isCreated = isCreated;
      }
      if (typeof isFailed !== 'undefined') {
        state.advancedVideo.isFailed = isFailed;
      }
    }
  },

  CLEAR_ADVANCED_VIDEO_DATA(state) {
    state.advancedVideo = {
      playlist_id: undefined,
      url: undefined,
      isCreated: false,
      isFailed: false,
    };
  },

  SET_MESSAGE_SERVER(state, msgServer: SSE) {
    state.msgServer = msgServer;
  },

  CLEAR_MESSAGE_SERVER(state) {
    state.msgServer?.close();
    state.msgServer = null;
  },

  UPDATE_IHEART_INGESTION_STATUS(state, { playlist_id, iheart_status, external_url }) {
    const index = state.activeShowEpisodes.findIndex((episode) => episode.playlist_id === Number(playlist_id));
    if (index !== -1) {
      Vue.set(state.activeShowEpisodes[index], 'iheart_status', iheart_status);
      Vue.set(state.activeShowEpisodes[index], 'external_url', external_url);
    }
  },

  /**
   *  Set Existing Images
   */
  GET_EXISTING_IMAGES(state, payload: string[]) {
    state.existing_images = payload;
  },

  DELETE_IMAGES(state, fileList: string[]) {
    if (fileList) {
      fileList.forEach((filePath) => {
        const index = state.existing_images.indexOf(filePath);
        if (index !== -1) {
          state.existing_images.splice(index, 1);
        }
      });
    }
  },

  SET_STATION_TRACKING_PREFIX_LIST(state, prefixObj) {
    // NOTE: prefix_list on station objects are NOT stringified.
    const prefixList = prefixObj.prefix || [];
    const currentStationName = state.settings.station_name.toLowerCase();
    const index = state.settings.station_list.findIndex(
      (station) => station.station.toLowerCase() === currentStationName
    );
    if (index !== -1) {
      Vue.set(state.settings.station_list[index], 'prefix_list', prefixList);
    }
  },

  SET_SHOW_TRACKING_PREFIX_LIST(state, { showId, prefixObj }) {
    // NOTE: prefix_list on show objects are stringified.
    const prefixList = prefixObj.prefix || [];
    if (showId) {
      // Sets the showWithTitle state to update the getters use on main page
      const show = state.showsWithTitles.find((show) => show.id === showId);
      if (show) {
        show.tracking_prefixes = prefixList;
      }
    }
  },

  UPDATE_P4P_PREMIUM(state, status: boolean) {
    Vue.set(state.settings, 'p4p_premium', status);
  },

  SET_BEST_PRACTICES_MANIFEST(state, manifest: BestPracticesManifest) {
    state.bestPracticesManifest = manifest;
  },

  // Bulk delete stacks, media library
  BULK_DELETE_STACKS(state, payload) {
    if (payload.deleted) {
      state.loggerEvents = state.loggerEvents.filter((event) => !payload.deleted.includes(event.id));
    }
  },

  // Bulk download stacks, media library
  BULK_DOWNLOAD_STACKS(state, payload) {
    const { status } = payload;
    // Add job
    if (!status) {
      state.bulkDownloadJobs.push({
        ...payload,
      });
      return;
    }

    // Modify existing job status
    if (['complete', 'error'].includes(status)) {
      const index = state.bulkDownloadJobs.findIndex((job) => job.guid === payload.guid);
      if (index !== -1) {
        if (status === 'complete') {
          const originalLength = state.bulkDownloadJobs[index].mp3_list.length;
          const completedLength = payload.audio_playlist_ids.length;
          const diff = originalLength - completedLength;
          if (diff) {
            state.message = {
              name: 'Bulk download',
              details: `${diff} of ${originalLength} files were not able to be downloaded.`,
              type: 'error',
            };
          }
        } else if (status === 'error') {
          state.message = {
            name: 'Bulk download',
            details: `Failed down download bulk files.`,
            type: 'error',
          };
        }
        state.bulkDownloadJobs.splice(index, 1, payload);
      }
    }
  },

  DELETE_COMPLETED_DOWNLOAD_STACK(state, id) {
    const index = state.bulkDownloadJobs.findIndex((job) => job.guid === id);
    if (index !== -1) {
      state.bulkDownloadJobs.splice(index, 1);
    }
  },

  SET_ENDPOINT: (state, endpoint) => {
    state.endpoint = endpoint;
  },

  SET_ALERT_MESSAGE: (state, payload) => {
    state.alertMessage = { ...payload };
  },

  SET_SHOULD_SHOW_ALERT: (state, boolean) => {
    state.shouldShowAlert = boolean;
  },
} as MutationTree<CreateAndPublishStoreState>;
