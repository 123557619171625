import { render, staticRenderFns } from "./TrialExpiredUser.vue?vue&type=template&id=61911f12&scoped=true&"
import script from "./TrialExpiredUser.vue?vue&type=script&lang=ts&"
export * from "./TrialExpiredUser.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61911f12",
  null
  
)

export default component.exports