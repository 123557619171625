import { prettifiedAPIUserErrors } from '@/constants/prettifiedAPIUserErrors';

const BadRequest = {
  // Generic codes
  DUPLICATE_BRAND_NAME_ERROR: 'No duplicate brand names',
  BRAND_NOT_IN_ACCOUNT_ERROR: 'Brand does not exist in your account',
  USER_NOT_IN_BRAND_ERROR: 'User does not have access to brand',
  EXCEEDS_MAX_BRANDS_ERROR: 'Max brands reached',
  DUPLICATE_CAMPAIGN_NAME_ERROR: 'This campaign name is already in use. Please enter a unique name.',
  DUPLICATE_EMAIL_ERROR: 'An account with the given email already exists.',
  // Prettified
  'Duplicate campaign name exists': 'This campaign name is already in use. Please enter a unique name.',
  'Campaign names not unique': 'This campaign name is already in use. Please enter a unique name.',
  'Duplicate Brand Name found': 'This brand name is already in use. Please enter a unique name.',
  'An account with the given email already exists.':
    "This email address is already in use in this company's account. Please enter a unique address.",
  'Invalid brand name':
    'Please enter a valid brand name. You may use letters, numbers, spaces and any of these special characters: ! @ # $ % ^ & * - ? ( )',
  'Max number of brands reached': 'Max brands reached',
  'invalid RSS Feed URL': 'Please enter a valid RSS feed URL.',
  'Duplicate rss feed name exists': 'This feed name is already in use. Please enter a unique name.',
  'Duplicate rss feed url exists': 'This RSS url is already in use.',
  ...prettifiedAPIUserErrors, // User errors
};

const SessionExpiration = {};

const Permission = {};

const Generic = {};

const AllErrors = {
  ...BadRequest,
  ...SessionExpiration,
  ...Permission,
  ...Generic,
};

export default {
  install(Vue) {
    // This function can be called globally for response handling
    // Example:
    // if(![201, 200].includes(response.status)){
    //     console.log(Vue.prototype.$ErrorPrettified(response.data))
    // }
    // Should return [ "No duplicate campaign names", "Max brands reached"]
    Vue.prototype.$errorPrettified = function (error) {
      if (Array.isArray(error)) {
        return error.map((e) => AllErrors(e));
      }

      return AllErrors[error];
    };
  },
};
