import { Product } from '@/types/Product';

/**
 *
 * @param products An aray of CC products
 * @returns An array of unique product IDs
 */
const getUniqueProductIds = (products: Product[]): number[] => {
  if (products?.length > 0) {
    return Array.from(new Set(products.map((product) => product.id)));
  }
  return []; // fallback
};

export { getUniqueProductIds };
