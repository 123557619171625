/**
 * Transforms number into readable string, truncating if over (+/-)100,000
 * @param {Number} val number to format
 * @returns {String} formatted value
 */
export function formatNumber(val: number): string {
  if (isNaN(val)) return '';
  const absVal = Math.abs(val);
  if (absVal > 999_999) {
    return (val / 1_000_000).toFixed(1) + 'M';
  } else if (absVal > 99_999) {
    return (val / 1_000).toFixed(1) + 'K';
  }
  return val.toLocaleString();
}

/**
 * Transforms number readable string, truncating if over (+/-)1,000
 * @param {Number} val percentage to format (e.g. .24 for 24%)
 * @returns {String} formatted value
 */
export function formatPercentChange(val: number): string {
  if (isNaN(val)) return '';
  const absVal = Math.abs(val * 100);
  if (absVal > 999_999) {
    return (absVal / 1_000_000).toFixed(1) + 'M%';
  } else if (absVal > 99_999) {
    return (absVal / 1_000).toFixed(1) + 'K%';
  }
  return Number(absVal.toFixed(0)).toLocaleString() + '%';
}
