import store from '@/store';
import { Brand } from '@/types/Brand';
import { Feature } from '@/types/features/FEFeature';

export type UnauthorizedRedirectQueryParams = {
  code: '2';
};

/**
 * Returns a URL param value when attemping to reach a path while unauthorized
 * Undefined is returned to not include a `type` query, whereever it's used
 * @param routePath Path that user attempted to reach
 * @param queryParams We use these to override product copy
 * Codes:
 *   2 -> no access to brand
 * @returns 'cnp', 'discover', undefined
 */
export const getUnauthorizedRedirectQueryType = (
  routePath: string,
  queryParams?: UnauthorizedRedirectQueryParams
): 'cnp' | 'discover' | undefined => {
  // If no access to brand, return undefined to show generic copy
  if (queryParams?.code === '2') {
    return undefined;
  }
  // If CNP
  if (routePath.includes('/create')) {
    return 'cnp';
  }
  // If Discover
  else if (routePath.includes('/discover')) {
    return 'discover';
  }
  // If anything else
  return undefined;
};

/**
 * Get a list of brands a user has access to and try find a brand that is in a route
 * @param userBrands Brands a user has access to
 * @param routeBrand ID of a brand in a route
 * @returns true, false
 */
export const matchRouteBrandWithUserBrands = (userBrands: Brand[] = [], routeBrand: string | undefined) => {
  if (userBrands?.findIndex((brand) => brand.id === Number(routeBrand)) !== -1) {
    return true;
  }
  return false;
};

export const addBrandIdToRoute = (path: string, brandId: string) => {
  if (path) {
    const splitPath = path.split('/');
    splitPath.splice(2, 0, brandId);
    return splitPath.join('/');
  }
};

export const isFeatureAvailable = (feature: Feature) => {
  return store.getters.features.includes(feature);
};
