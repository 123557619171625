/* eslint-disable @typescript-eslint/no-non-null-assertion */

import router from '@/router';

export default class UserSettings {
  private storeName = '__ccUserSettings';

  constructor() {
    this.ensureStoreExists();
  }

  public get(propertyName: string): unknown {
    const fullPath = this.getFullPath() as string;
    try {
      const store = JSON.parse(window.localStorage.getItem(this.storeName)!);
      return store[fullPath][propertyName];
    } catch {
      return undefined;
    }
  }

  public save(propertyName: string, value: unknown) {
    const fullPath = this.getFullPath();
    if (!fullPath) {
      throw new Error("UserSettings.save couldn't find route path.");
    }

    try {
      this.ensurePropertyPathExists();
      const store = JSON.parse(window.localStorage.getItem(this.storeName)!);
      Object.defineProperty(store[fullPath], propertyName, {
        value,
        enumerable: true,
      });
      window.localStorage.setItem(this.storeName, JSON.stringify(store));
    } catch (e) {
      console.warn('Unable to add user setting to localStorage', e);
    }
  }

  private getFullPath(): string {
    return router.currentRoute.fullPath;
  }

  private ensureStoreExists() {
    if (!window.localStorage.getItem(this.storeName)) {
      window.localStorage.setItem(this.storeName, JSON.stringify({}));
    }
  }

  private ensurePropertyPathExists() {
    const store = JSON.parse(window.localStorage.getItem(this.storeName)!);
    const fullPath = this.getFullPath() as string;

    if (Object.prototype.hasOwnProperty.call(store, fullPath)) return;

    Object.defineProperty(store, fullPath, {
      value: {},
      enumerable: true,
    });

    window.localStorage.setItem(this.storeName, JSON.stringify(store));
  }
}
