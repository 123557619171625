/**
 * This module is used to get playlists for the currently active show and
 * provide some additional metadata about them collectively for display.
 *
 * It is also used by the C&P Planner
 */

import { handleAjax } from '../core/util';
import HttpClient from '@/services/common/HttpClient';
import type { PlaylistPreview } from '@/types/createandpublish';
import type { RootState } from '@/types/store';
import type { Module } from 'vuex';

export default {
  namespaced: true,

  state: {
    playlists: [],
    page: 1,
    pageSize: 10,
    sort: 'id',
    order: 'DESC',
    type: 'published',
    searchTerm: '',
    start: NaN, // Used if type = 'calendar'
    end: NaN, // Used if type = 'calendar'
  },

  getters: {
    playlists(state) {
      return state.playlists;
    },

    currentPage(state) {
      return state.page;
    },

    pageSize(state) {
      return state.pageSize;
    },

    sort(state) {
      return state.sort;
    },

    order(state) {
      return state.order;
    },

    currentType(state) {
      return state.type;
    },

    searchTerm(state) {
      return state.searchTerm;
    },

    activeShowId(_state, _getters, rootState) {
      return rootState.CreateAndPublishStore.activeShowId ?? NaN;
    },

    activeShowCounts(_state, getters, _rootState, rootGetters) {
      const activeShowId = parseInt(getters.activeShowId);
      if (activeShowId) {
        // Playlist counts are only returned by really_all.json, so we have to find them in the data returned from there
        const activeShow =
          rootGetters['CreateAndPublishStore/unfilteredShows']?.find((show) => show.id === activeShowId) || null;
        if (activeShow) {
          return activeShow.counters;
        }
      }
      return {
        published: 0,
        scheduled: 0,
        unpublished: 0,
        draft: 0,
      };
    },

    totalPlaylistsOfActiveType(state, getters) {
      return getters.activeShowCounts[state.type];
    },

    playlistParams(state, getters) {
      const activeShowId = getters.activeShowId as number;
      const { page, pageSize, sort, order, type, start, end } = state;

      const urlSearchParams = new URLSearchParams();

      urlSearchParams.set('type', type);
      urlSearchParams.set('sort', sort);
      urlSearchParams.set('order', order);

      if (type === 'calendar') {
        urlSearchParams.set('start', start.toString());
        urlSearchParams.set('end', end.toString());
      } else {
        urlSearchParams.set('show_id', activeShowId.toString());
        urlSearchParams.set('page', page.toString());
        urlSearchParams.set('limit', pageSize.toString());
      }

      return `?${urlSearchParams.toString()}`;
    },
  },

  mutations: {
    SET_PLAYLISTS(state, data) {
      state.playlists = data;
    },

    CLEAR_PLAYLISTS(state) {
      state.playlists = [];
    },

    SET_PAGE(state, page) {
      state.page = page;
    },

    SET_PAGE_SIZE(state, size) {
      state.pageSize = size;
    },

    SET_SORT(state, sort) {
      state.sort = sort;
    },

    SET_ORDER(state, order) {
      state.order = order;
    },

    SET_TYPE(state, type) {
      state.type = type;
    },

    SET_SEARCH_TERM(state, searchTerm) {
      state.searchTerm = searchTerm;
    },

    SET_RANGE(state, { start, end }: { start: number; end: number }) {
      state.start = start;
      state.end = end;
    },
  },

  actions: {
    async fetchPlaylists({ dispatch, commit, rootState, getters }) {
      const http = HttpClient.getInstance();
      const endpoint = rootState.CreateAndPublishStore.endpoint;
      const url = `${endpoint}/episodes${getters.playlistParams}`;
      return handleAjax({
        request: http.get(url),
        dispatch,
        commit,
        mutation: 'SET_PLAYLISTS',
        errmsg: 'Unable to retrieve episodes.',
      });
    },
  },
} as Module<GetPlaylistsState, RootState>;

export interface GetPlaylistsState {
  playlists: PlaylistPreview[];
  page: number;
  pageSize: number;
  sort: 'id' | 'description' | 'duration' | 'name';
  order: 'DESC' | 'ADC';
  type: 'published' | 'scheduled' | 'unpublished' | 'draft' | 'calendar';
  searchTerm: string;
  // if type = 'calendar', then optionally specify start and end dates
  start: number;
  end: number;
}
