import Vue from 'vue';
import Vuex from 'vuex';

import RootStore from '@/store/modules/RootStore';

import MediaQueries from './modules/MediaQueriesStore';
import UserStore from './modules/UserStore';
import BrandStore from './modules/BrandStore';

import CurrentUser, { moduleName as currentUserModuleName } from '@/store/modules/CurrentUserStore';
import VuexPersistence from 'vuex-persist';

import AccountStore from './modules/AccountStore';
import EcommStore from '@/store/modules/EcommStore';
import DiscoverStore from '@/store/modules/DiscoverStore';

import CreateAndPublishStore from '@/createandpublish/core/store';
import AudienceAnalyticsStore from '@/audience/core/store';
import { rootInitialState } from '@/constants/store/rootInitialState';

import type { RootState } from '@/types/store';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state: RootState) => ({
    AccountStore: state.AccountStore,
    RootStore: state.RootStore,
    BrandStore: {
      selectedBrand: state.BrandStore.selectedBrand,
    },
    ...state.RootStore,
  }),
});

const store = new Vuex.Store({
  state: {} as RootState,
  mutations: {
    CLEAR_STORE: (state) => {
      Object.keys(rootInitialState).forEach((key) => {
        state[key] = rootInitialState[key];
      });
    },
  },
  modules: {
    RootStore,
    AccountStore,
    EcommStore,
    UserStore,
    BrandStore,
    MediaQueries,
    [currentUserModuleName]: CurrentUser,
    DiscoverStore,
    CreateAndPublishStore,
    AudienceAnalyticsStore,
  },
  plugins: [vuexLocal.plugin],
});

export default store;
