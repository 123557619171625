import { initialState as AccountStore } from '@/store/modules/AccountStore';
import { initialState as AudienceAnalyticsStore } from '@/audience/core/store';
import { initialState as BrandStore } from '@/store/modules/BrandStore';
import { initialState as CreateAndPublishStore } from '@/createandpublish/core/store';
import { initialState as DiscoverStore } from '@/store/modules/DiscoverStore';
import { initialState as MediaQueries } from '@/store/modules/MediaQueriesStore';
import { initialState as RootStore } from '@/store/modules/RootStore';
import { initialState as UserStore } from '@/store/modules/UserStore';
import { initialState as currentUser } from '@/store/modules/CurrentUserStore';

const initialStateBaseLevel = {
  adminEmail: '',
  authToken: '',
  authenticatedUser: null,
  billing: undefined,
  roles: undefined,
};

export const rootInitialState = {
  AccountStore,
  AudienceAnalyticsStore,
  BrandStore,
  CreateAndPublishStore,
  DiscoverStore,
  MediaQueries,
  RootStore,
  UserStore,
  currentUser,
  ...initialStateBaseLevel,
};
